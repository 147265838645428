@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

.sport-home-page__betting-start {
  padding: 0 10px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $sub-nav-menu-text;
    color: $header-text;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    img {
      cursor: pointer;
      width: 12px;
    }
  }
}