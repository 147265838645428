@import '../../../../static/scss/variables.scss';

.custom-button-default {
  background-color: $header-userMenu-button;
  min-width: 64px;
  border-radius: 5px;
  display: inline-flex;
  padding: 13px 84px;
  align-items: center;
  justify-content: center;
  border: unset;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  color: $account-detail-pages-apply-button-text;
  text-transform: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.4s;
  overflow: hidden;
  position: relative;

  .custom-button-default-span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    opacity: 0.2;
    border-radius: 100%;
    background: $common-white;
    transform: translate(-50%, -50%);
    z-index: 11;
  }
}

.custom-button-default.disabled {
  color: $account-detail-pages-item-text;
  box-shadow: none;
  background-color: $account-detail-pages-disable-button-bg;
  cursor: default;
  pointer-events: none;
}

.custom-button-default.fullWidth {
  width: 100%;
}

.custom-button-default:hover {
  background-color: $header-userMenu-button;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.custom-button-default:active {
  background-color: $header-userMenu-button;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.custom-button-default-span.active-span {
  animation: click-span 0.4s forwards linear;
}

@keyframes click-span {
  to {
    width: calc(100% * 2.25);
    padding-top: calc(100% * 2.25);
  }
}