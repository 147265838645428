.pointPopUp-wrapper {
  width: 100%;
  height: 100%;
  z-index: 16;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointPopUp {
  width: 50%;
  max-width: 721px;
  height: 448px;
  border-radius: 10px;
  padding: 25px 33px 33px 33px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  animation: openDetailsDesk 0.25s linear forwards;
  &.close {
    animation: closeDetailsDesk 0.25s linear forwards;
  }
  &__content {
    position: relative;
    width: 100%;
    max-width: 752px;
    height: 432px;
    padding: 0 0 20px 0;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    color: black;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), inset -3px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-img {
      position: absolute;
      content: url("../../../static/images/pointsPopUp/pointPopUpImage.svg");
      width: 50%;
      top: -75px;
    }
    &-closeButton {
      position: absolute;
      right: -15px;
      top: -15px;
      width: 44px;
      height: 44px;
      cursor: pointer;
    }
    &-title {
      display: flex;
      justify-content: center;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #252b37;
      margin-top: 100px;
    }
    &-descr {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 26px;
      color: #007b59;
      margin-top: 11px;
    }
    &-welcome {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #252b37;
      margin-top: 14px;
    }
    &-checkout {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #252b37;
      margin-top: 35px;
      width: 414px;
      height: 41px;
    }
    &-button {
      background: #017b5a;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 280px;
      height: 44px;
      border-radius: 15px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 52px;
      cursor: pointer;
    }
  }
  @keyframes openDetailsDesk {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes closeDetailsDesk {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
}

@media screen and (max-width: 1024px) {
  .pointPopUp {
    width: unset;
    &__content {
      width: 277px;
      height: 470px;
      &-img {
        left: 25%;
        top: -70px;
        height: 150px;
      }
      &-closeButton {
        right: -10px;
        top: -10px;
        height: 30px;
      }
      &-title {
        margin-top: 45px;
      }
      &-checkout {
        width: 90%;
      }
      &-button {
        width: 230px;
      }
    }
  }
}