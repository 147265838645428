@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

@include respond(Medium) {
    div.jili-popup {
        &-content {
            padding: 10px;
            border-radius: 15px;
            width: 95%;
        }

        &-header {
            button.jili-popup-header__close {
                margin-top: 2px;
                margin-right: 2px;
            }
        }
        
        &-container {
          padding: 0 0 40px ;
          &-heading {
            margin-top: 16px;
            font-size: 24px;
            line-height: 36px;
          }
          &-text {
            margin: 8px 25px 20px 25px;
            font-size: 14px;
            line-height: 21px;
          }
          &-btns {
            &--button {
              margin-top: 15px;
              margin-bottom: 0;
            }
          }
        }
    }
}

