@import "./phone.scss";

.grouped-games {
  display: flex;
  width: 100%;
  flex-direction: column;
  .live-header {
    background: $primary-background;
  }

  &__bodyy {
    display: grid;
    background: $primary-background;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-auto-columns: min-content;
    grid-column-gap: 1vw;
    grid-row-gap: 2vw;
    margin-top: 20px;

    &_item {
      width: 199px;
      border-radius: 15px;
      overflow: hidden;
      cursor: pointer;
    }

    &_img-body {
      height: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      position: relative;

      &:hover {
        background-size: 115%;
        transition: background-size 0.5s;
      }
    }
  }
}

.slot__body-cards-name {
  display: none;
}

.slot__fix-top {
  position: relative;
  width: 100%;
}

.slot__fix-top::before {
  display: none;
}

.slot__fix-top {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.slot__menu {
  display: flex;
  height: 50px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  border-radius: none;
  &-item {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 0 20px;
    white-space: nowrap;
    color: #000000;
    border-bottom: 2px solid transparent;
    &.active {
      color: #000;
      border-bottom: 2px solid #00A826;
    }
  }
}

.slot__body-cards {
  border-radius: 10px;
}

.live-casino__game {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  font-family: 'Lato';
  font-style: normal;
  &-title {
    margin-top: 50px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }
  &-descr {
    margin-top: 10px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }
  &-text {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 50px;
  }
}

.grouped-games__bodyy {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .live-header {
    background: #000 !important;
  }

  .live-casino__game {
    display: none;
  }

  .slot__menu {
    display: flex;
    height: 60px;
    width: calc(100% - 20px);
    margin: 0 auto;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 10px 10px 0px 0px;
    overflow-x: scroll;
  }

  .slot__fix-top::before {
    display: none;
  }

  .grouped-games__bodyy {
    width: 100%;
    max-width: 365px;
    margin: 0 auto;
    padding-top: 20px;
    grid-column-gap: 2vw;
    grid-template-columns: 1fr 1fr;
  }
}
