@import './phone.scss';
@import '../../../../static/scss/variables.scss';

@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';

@media (min-width: 1024px) {
  .change-password {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    padding: 30px 20px !important;
    color: $change-password-color;
    width: 100%;
    height: 100%;
    z-index: 16;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    .change-password__change-password-form {
      width: 500px;
      padding-bottom: 30px;
      border-radius: 20px;
      background-color: $change-password-bg;
      overflow: hidden;
      transition: all 0.3s;
      animation: openDetailsDesk 0.3s linear forwards;

      &-header {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        background-color: $change-password-button-bg-active;
        span {
          font-weight: 500;
          font-size: 22px;
          color: $change-password-button-color;
        }
        img {
          cursor: pointer;
        }
      }
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      margin: 35px 0 10px 0;
      padding: 0 35px;
    }
    &__text {
      font-weight: 400;
      margin-bottom: 20px;
      padding: 0 35px;
    }
    .MuiInputBase-root {
      background-color: unset;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
    }

    .MuiButton-contained {
      margin-top: 18px;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
      border-radius: 5px;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      display: none;
    }

    .change-password__form {
      padding: 0 35px;
    }
    .hidden-custom input[name="password"] {
      -webkit-text-security: unset !important;
    }
    .change-password__form-item {
      margin-bottom: 12px;
      &-title {
        font-weight: 700;
        margin-bottom: 10px;
      }
      .formik-field {
        background: unset;
        margin: unset;
        max-width: unset;
        input {
          height: 50px;
          padding: 0 12% 0 25px;
        }
        fieldset legend {
          display: none;
        }
        fieldset {
          height: 50px;
          top: 0;
        }
        label {
          color: $change-password-color;
          transform: translate(25px, 20px) scale(1);
        }
        img {
          top: 14px;
        }
        .MuiFormHelperText-root {
          color: $account-detail-changePassword-validationError;
        }
      }
    }
    .change-password__form-button {
      background-color: $change-password-button-bg-active;
      color: $change-password-button-color;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      width: 100%;
      line-height: 0;
      height: 50px;
      img {
        width: 15px;
        height: auto;
        margin-left: 15px;
      }
      &.disabled {
        background-color: $change-password-button-bg;
      }
    }
    .change-password__privacy-policy {
      font-weight: 400;
      font-size: 13px;
      display: flex;
      align-items: center;
      margin-top: 13px;
      .MuiCheckbox-colorSecondary {
        margin: unset;
        color: rgba(0, 0, 0, 0.26);
        padding: unset;
        margin-right: 17px;
      }
      span.MuiButtonBase-root {
        color: black;
      }
      &-link {
        color: $change-password-term-color;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .change-password__change-password-form.close {
    animation: closeDetailsDesk 0.3s linear forwards;
  }
  @keyframes openDetailsDesk {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes closeDetailsDesk {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
}
