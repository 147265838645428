@import "phone.scss";
@import '../../../static/scss/variables.scss';

.side-menu {
  position: absolute;
  z-index: 10;
  width: 350px;
  height: 100vh;
  //background: $leftside-menu-header-bg;

  & a {
    color: $leftside-menu-text;
  }

  .section-wrapper {
    position: relative;
    padding: 14px 20px 14px 11px;
    background-color: #fff;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    span {
      position: absolute;
      left: 15px;
      top: 15px;
    }
  }

  .wrapper {
    height: 100%;
    padding-bottom: 150px;
    overflow-y: auto;
    background: $leftside-menu-content-bg;
    animation: openWrapper 0.3s ease-in-out forwards;

    &::-webkit-scrollbar {
      /* chrome based */
      width: 0px;
      /* ширина scrollbar'a */
      height: 0px;
      background: transparent;
      /* опционально */
      display: none;
    }
    &.close {
      animation: closeWrapper 0.3s ease-in-out forwards;
    }
  }
  @keyframes closeWrapper {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes openWrapper {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .top {
    height: 52px;
    background: $nav-menu-background;
    display: none;
    align-items: center;
    padding: 0 5px;
    cursor: pointer;

    .close {
      background: $nav-menu-background;
      border-radius: 5px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: $leftside-menu-text;
      margin-left: 5px;
    }
  }

  .search-bar {
    display: none;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 0;
    justify-content: space-between;
    box-shadow: none;

    input {
      margin-left: 15px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #fff;
    }
  }

  .main {
    width: 80%;
    margin: 30px auto;

    .title {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: $leftside-menu-text;
    }

    .games {
      margin-top: 18px;

      .item {
        height: 36px;
        width: 100%;
        padding-left: 15px;
        margin-left: -15px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: $leftside-menu-text;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        svg {
          height: 12px;
        }
      }
    }
  }
}
