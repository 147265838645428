// @import '../../../static/scss/media.scss';
@import '../../static/scss/variables.scss';

.desktop_only {
    display: block;
}
.mobile_only {
    display: none;
}

.special-offers_page {
    padding: 0px !important;
    font-family: 'Roboto';

    .top_banner {
        
        img.tb_image {
            width: 100%;
        }
    }

    .offer_wrapper {
        padding: 45px 0;

        .item-box {
            padding: 30px;
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 15px;
            border-radius: 10px;
            box-shadow: 0 2px 4px 0 #0000000d;

            .image {
                width: 100%;

                img {
                    width: 100%;
                }
            }
            
            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 10px;
        
                .heading {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 500;
                }
                
                .text {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                }
                
                a.rm {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #00A826
                }
                button.button {
                    background-color: #00A826;
                    color: #F8F9FB;
                    display: flex;
                    padding: 12px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    align-items: center;
                    gap: 10px;
                    justify-content: center;
                    border-radius: 5px;
                }
            }
        }
    
        .slider-container {
            .slider-control-centerleft button {
                font-size: 0;
                width: 60px;
                height: 60px;
                border-radius: 100%;
                background-color: #fff !important;
                opacity: 1 !important;
                box-shadow: 0 0 20px 0 #00000026;
                position: relative;

                &:before {
                    content: " ";
                    border-left: 3px solid #000;
                    border-bottom: 3px solid #000;
                    width: 12px;
                    height: 12px;
                    transform: rotate(45deg);
                    cursor: pointer;
                    display: block;
                    position: absolute;
                    left: 26px;
                    top: 26px;
                }
            } 
            .slider-control-centerright button {
                font-size: 0;
                width: 60px;
                height: 60px;
                border-radius: 100%;
                background-color: #fff !important;
                opacity: 1 !important;
                box-shadow: 0 0 20px 0 #00000026;
                position: relative;

                &:before {
                    content: " ";
                    border-right: 3px solid #000;
                    border-top: 3px solid #000;
                    width: 12px;
                    height: 12px;
                    transform: rotate(45deg);
                    cursor: pointer;
                    display: block;
                    position: absolute;
                    left: 20px;
                    top: 26px;
                }
            }
            
            .slider-control-bottomcenter {
                display: none;
            }
        }
    }
}

@media (max-width: 1080px) and (min-width: 768px) { 
    .special-offers_page {

        .offer_wrapper {
        
            .item-box {
                padding: 15px;
            }

            .slider-container .slider-control-centerright button {
                width: 40px;
                height: 40px;

                &:before {
                    width: 12px;
                    height: 12px;
                    left: 12px;
                    top: 16px;
                }
            }
            .slider-container .slider-control-centerleft button {
                width: 40px;
                height: 40px;

                &:before {
                    width: 12px;
                    height: 12px;
                    left: 16px;
                    top: 16px;
                }
            }
        }
    }
}

@media (max-width: 767px) {

    .desktop_only {
        display: none !important;
    }
    .mobile_only {
        display: block !important;
    }

    .special-offers_page {
        .top_banner {
            padding: 20px 20px 60px 20px;
            background: #000;
            margin-bottom: -40px;
        }
        
        .offer_wrapper {
            padding: 0 15px 20px 15px;

            .item-box {
                padding: 15px;
            }

            .slider-control-centerleft, 
            .slider-control-centerright {
                display: none;
            }
            .slider-list {
                transform: unset !important;
                width: 100% !important;
                flex-wrap: wrap;
            
                .slide {
                    width: 100% !important;
                    flex: 1 1 100% !important;
                }
            }
        }
    }
}