@import "phone.scss";
@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';

.home-menu-new {
  width: 100%;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  img {
    width: 100%;
    height: auto;
  }
  .home-menu-new__main {
    display: flex;
    align-items: center;
    padding: 10px 0;
    overflow-x: scroll;
    transition: all 0.5s;
    &-item {
      width: 110px;
      min-width: 110px;
      height: 25px;
      border-radius: 5px;
      color: $home-header-color;
      background-color: $home-header-button-bg;
      text-transform: uppercase;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      transition: all 0.5s;
      overflow: hidden;
      &.active {
        background-color: $home-header-button-active-bg;
      }
      &.new{
        border: 1px solid #FF4040;
        &::before{
          content: 'New';
          height: 12px;
          display: flex;
          font-family: 'Roboto';
          justify-content: center;
          align-items: center;
          width: 41px;
          top: 3px;
          left: -12px;
          background-color: #FF4040;
          font-size: 8px;
          position: absolute;
          transform: rotate(-45deg);
        }
      }
      &.wc{
        background-color:  #CAA511;
      }
    }
    &-item:first-child {
      margin-left: 10px;
    }
  }
  .home-menu-new__sub {
    display: flex;
    height: 34px;
    width: calc(100% - 20px);
    margin: 0 auto;
    background-color: $home-header-sub-menu-bg;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 10px 10px 0px 0px;
    overflow-x: scroll;
    transition: all 0.3s;
    &-item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      padding: 0 10px;
      white-space: nowrap;
      color: $home-header-color-secondary;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      animation: menuMobile 0.3s linear forwards;
      &-img {
        margin-right: 7px;
        width: max-content;
        cursor: pointer;
      }
    }
    &-item.active {
      transition: all 0.5s;
      border-bottom: 2px solid $home-header-button-active-bg;
  }
  }
  @keyframes menu {
    0% {
      padding: 0 10px;
      opacity: 0.3;
    }
    100% {
      padding: 0 20px;
      opacity: 1;
    }
  }
  @keyframes menuMobile {
    0% {
      padding: 0 5px;
      opacity: 0.3;
    }
    100% {
      padding: 0 10px;
      opacity: 1;
    }
  }
  @keyframes active {
    0% {
      border-bottom: 2px solid transparent;
      opacity: 0.3;
    }
    100% {
      border-bottom: 2px solid $home-header-button-active-bg;
      opacity: 1;
    }
  }
  .home-menu-new__content.bottom {
    background-color: $home-header-sort-menu-bg;
  }
  .home-menu-new__sub-sort {
    display: flex;
    height: 45px;
    width: calc(100% - 20px);
    margin: 0 auto;
    background-color: $home-header-bg;
    border-radius: 0px 0px 10px 10px;
    color: $home-header-sort-menu-color;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Lato';
    overflow-x: scroll;
    transition: all 0.5s;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.5s;
      &.active {
        color: $home-header-color;
      }
    }
  }
  .home-menu-new__main::-webkit-scrollbar,
  .home-menu-new__sub-sort::-webkit-scrollbar,
  .home-menu-new__sub::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
}
@media (max-width: 390px) {
  .home-menu-new {
    .home-menu-new__sub {
      height: 34px
    }
  }
}
@media (min-width: 1024px) {
  .home-menu-new {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
    margin-top: -60px;
    position: relative;
    .home-menu-new__main {
      display: flex;
      align-items: center;
      padding: unset;
      height: 60px;
      background-color: $home-header-bg-secondary;
      position: relative;
      z-index: 1;
      &-item {
        min-width: 110px;
        width: auto;
        padding: 0 20px;
        height: 100%;
        border-radius: unset;
        color: $home-header-color;
        background-color: transparent;
        text-transform: uppercase;
        margin-right: unset;
        &.active {
          background-color: $home-header-button-active-bg-secondary;
        }
        &.new{
          border: unset;
          &::before{

            content: url('../../../../static/images/icons/newGameIcon.svg');
            border: unset;
            transform: unset;
            top: -6px;
            right: 4px;
            width: 63px;
            height: 28px;
            position: absolute;
            left: unset;
            background-color: unset;
        }
        &::after{
          content: 'NEW';
          font-size: 8px;
          position: absolute;
          top: -1px;
          right: 27px;
        }
      }
      }
      &-item:first-child {
        margin-left: unset;
      }
    }
    .home-menu-new__sub {
      display: flex;
      height: 50px;
      width: 100%;
      background-color: $home-header-sub-menu-bg;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
      border-radius: unset;
      &-item {
        width: max-content;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        padding: 0 20px;
        white-space: nowrap;
        color: $home-header-color-secondary;
        border-bottom: 2px solid transparent;
        animation: menu 0.3s linear forwards;
        &-img {
          margin-right: 7px;
          width: max-content;
        }
      }
      &-item.active {
        border-bottom: 2px solid $home-header-button-active-bg;
      }
    }
    .home-menu-new__content.bottom {
      background-color: $home-header-sort-menu-bg;
    }
    .home-menu-new__sub-sort {
      display: flex;
      height: 50px;
      width: 100%;
      margin: 0 auto;
      background-color: $home-header-bg;
      border-radius: unset;
      color: $home-header-sort-menu-color;
      font-weight: 700;
      font-size: 14px;
      font-family: 'Lato';
      overflow-x: scroll;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 25px;
        white-space: nowrap;
        &.active {
          color: $home-header-color;
        }
      }
    }
    .home-menu-new__main::-webkit-scrollbar,
    .home-menu-new__sub-sort::-webkit-scrollbar,
    .home-menu-new__sub::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}