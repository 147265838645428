@import '../../static/scss/variables.scss';

.competition-list {
  .competition-list__dropdown {
    padding: 0 10px;
    &-content {
     display: flex;
      height: 35px;
      align-items: center;
      justify-content: space-between;
      background: #FFFFFF;
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 10px 18px;
      margin-top: 5px;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      cursor: pointer;
    }
  }
}




.competitionList__pop-up.mobile{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3;
}
.competitionList__pop-up-content {
  width: 100%;
  max-height: 85%;
  position: absolute;
  animation: openPopUp 0.6s ease-in-out forwards;
  z-index: 2;
  padding: 50px 42px 40px 42px;
  transition: bottom 0.5s;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #15161B;
  overflow: auto;
  text-align: center;
  &_items{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-item{
      width: 100%;
      min-height: 42px;
      background-color: #f5f5f5;
      border-radius: 10px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      padding: 0 10px;
      &.active{
        background-color: #00ab26;
        color: white;
      }
    }
  }
  &.closed{
    animation: closePopUp 0.6s ease-in-out forwards;
  }
  @keyframes openPopUp {
    0% {
      bottom: -100%
    }
    100% {
      bottom: 0%
    }
  }
  @keyframes closePopUp {
    0% {
      bottom: 0%
    }
    100% {
      bottom: -100%
    }
  }
}
@media (min-width: 1024px) {
  .competition-list {
    width: min-content;
    position: relative;
    .competition-list__dropdown {
      padding: unset;
      width: 410px;
      &-content {
        display: flex;
        height: 45px;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 10px 18px;
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        cursor: pointer;
        .competition-list__dropdown-content-image {
          transition: all 0.3s;
          img {
            width: 10px;
            height: 10px;
          }
          &.active {
            transform: rotateX(-180deg);
          }
        }
      }
    }
  }
  .competition-list__dropdown-content-items {
    width: 410px;
    background-color: $common-white;
    padding: 30px;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 50px;
    transform: translateY(-50%) scaleY(0);
    transition: all 0.3s;
    overflow: hidden;
    opacity: 0;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .competition-list__dropdown-content-items-one {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: #F5F5F5;
      margin-bottom: 8px;
      cursor: pointer;
      transition: all 0.3s;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      &.active {
        background-color: #00A826;
        color: white;
      }
      &:hover {
        transform: scale(1.01);
      }
      &:active {
        transform: scale(1.01);
      }
    }
    &.active {
      z-index: 1;
      transform: translateY(0%) scaleY(1);
      height: auto;
      opacity: 1;
    }
  }
}
