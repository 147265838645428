@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';

.balance-new {
  color: $header-button-text-secondary;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s;
}

@media (max-width: 1024px) {
  .balance-new {
    font-size: 13px;
  }
  .wallet-pop-up {
    width: 100%;
    height: auto;
    position: absolute;
    animation: boxAppend 0.6s ease-in-out;
    z-index: 2;
    padding: 46px 43px 43px 52px;
    bottom: 0%;
    top: unset;
    animation: unset;
    transition: bottom .5s;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 30px 30px 0px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #15161B;
    &.closed{
      bottom: -100%;
    }
    .wallet-pop-up__button {
      width: 100%;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      font-family: 'Roboto';
      font-weight: 600;
      font-size: 14px;
      &.deposit {
        background-color: #00A826;
        color: #FFFFFF;
      }
      &.withdrawal {
        margin-top: 8px;
        background-color: #F5F5F5;
        color: #000000;
      }
      &.cancel {
        margin-top: 23px;
        background-color: transparent;
        border: 1px solid #9DA2AF;
        color: #9DA2AF;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
}
