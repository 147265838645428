@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

@include respond(Medium) {
  div .global-rules-popup {
    padding-top: 50px;

    &-container {
      padding: 30px 15px;
    }

    &-content {
      max-height: 90vh;
      overflow: auto;
    }

    &-container__desktop-rules-text-wrapper {
      display: none;
    }

    &-header__text span {
      font-size: 16px;
    }

    &-header img.globalRulesClose {
      width: 20px;
      height: 20px;
    }

    &-container__rules-list-wrapper {
      width: 100%;
    }

    &-container__rules-list {
      overflow: auto;
      width: 100%;
    }

    &-container__rules-list-item {
      border: none;
      margin-bottom: 3px;
      padding: 0;

      article {
        padding: 0;
        max-height: 0;
        transition: max-height 0.15s ease-out;
        overflow: hidden;
      }
    }

    &-container__rules-list-item.active article {
      padding: 8px 0;
      overflow: auto;
      max-height: 500px;
      transition: max-height 0.25s ease-in;

      p {
        margin-bottom: 8px;
      }
    }

    &-container__rules-list-item-title {
      font-size: 16px;
      padding: 12px 15px;
      background: $primary-background;
      color: $main-text-color;
    }
  }
}