@import "phone.scss";
@import '../../../../static/scss/variables.scss';

.betslip {
  height: 371px;
  position: relative;
  user-select: none;

  &__loader {
    &-container {
      position: absolute;
      left: 50%;
      bottom: 50%;
    }

    position: relative;
    left: -50%;
    z-index: 100;
  }

  &__header {
    background: $sport-event-details-betslip-header-bg;
    color: $sport-event-details-betslip-header-text;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 15px;

    &-name {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    &-close {
      border: none;
      background: transparent;
      padding: 0;
      display: flex;
      width: 31px;
      height: 24px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 40px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .wrapper {
    height: auto;
    position: absolute;

  }

  &__body {
    background: $sport-event-details-betslip-content-bg;
    height: 341px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    color: $sport-event-details-betslip-content-text;

    &-name {
      font-size: 16px;
      line-height: 24px;
      padding: 15px 15px 0 15px;
      color: $sport-event-details-betslip-content-text;
    }

    &-details {
      font-size: 14px;
      line-height: 18px;
      padding: 5px 15px 0 15px;
      color: $sport-event-details-betslip-content-text;

      &-name {
        display: inline-block;
        width: 26%;
        padding-right: 15px;
        color: $sport-event-details-betslip-content-text;
      }
    }

    &-place-bet {
      padding: 15px 7px;
      margin: 15px 7px 0 7px;
      position: relative;
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      // grid-column-gap: 7px;
      .redCross{
        height: 28px;
        width: 28px;
        border-radius: 50%;
        background: #E24C41;
        position: absolute;
        right: -10px;
        top: -10px;
        border: 4px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.back {
        background: $sport-event-details-betslip-content-back-bg;
      }

      &.lay {
        background: $sport-event-details-betslip-content-lay-bg;
      }

      &_group {
        margin-bottom: 20px;

        &-title {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #000000;
          margin-bottom: 10px;
        }

        &-input {
          position: relative;

          .clearInput{
            position: absolute;
            top: 12px;
            right: 10px;
            cursor: pointer;
          }

          input {
            background: $sport-event-details-betslip-input;
            color: $sport-event-details-betslip-input-text;
            border-radius: 5px;
            padding: 5px 18px;
            border: none;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            width: 100%;

            &::placeholder {
              font-size: 16px;
              line-height: 24px;
              color: $sport-event-details-betslip-input-text;
            }
          }

          &>span {
            background: transparent;
            border: none;
            font-size: 18px;
            line-height: 24px;
            color: $sport-event-details-betslip-content-text;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.place-bet__input-odd {
              &-minus {
                left: 7px;
                background: #000;
                width: 23px;
                position: absolute;
                background: #000;

                &:hover {
                  cursor: pointer;
                }
              }

              &-plus {
                right: 7px;
                background: #000;
                width: 23px;
                position: absolute;
                background: #000;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }

          &.disabled {
            span {
              display: none;
            }
          }
        }
      }

      &_odd {
        width: 50%;
        grid-column-start: 1;
        grid-column-end: 3;

        &.fancy {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .fancy__item {
            text-align: center;
            margin-right: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .betslip__body-place-bet_group-title {
              margin-bottom: 8px;
            }

            .betslip__body-place-bet_group-result {
              line-height: 24px;
              font-size: 17px;
              margin-top: 5px;
            }
          }
        }
      }

      &_stake {
        grid-column-start: 3;
        grid-column-end: 5;

        input {
          text-align: left;
          padding: 5px 24px 5px 14px;
        }
      }

      &_item {
        // font-size: 16px;
        // line-height: 24px;
        // color: $sport-event-details-betslip-content-increment-button-text;
        // background: $sport-event-details-betslip-content-increment-button;
        // border: none;
        // border-radius: 5px;
        // text-align: center;
        // padding: 3px 0;
        // margin-bottom: 5px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #000000;
        width: 84.22px;
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3px 7px 3px 0;

        &:active {
          // background-color: $sport-event-details-betslip-content-increment-active;
          background: rgba(0, 168, 38, 0.4)
        }

        &:before {
          content: '+';
        }

        &:hover {
          cursor: pointer;
        }
      }

      &_itemm{
        background: #FFFFFF;
        border: 1px solid #00A826;
        border-radius: 3px;
        width: 53px;
        height: 28px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: #00A826;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3px 0 3px 0;
        cursor: pointer;

      }

      &_reset {
        font-size: 16px;
        line-height: 24px;
        color: $sport-event-details-betslip-content-increment-button-text;
        background: $sport-event-details-betslip-cancel-button;
        border-radius: 5px;
        border: none;
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 8px 0;
        margin-top: 10px;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          background-color: $complementary-grey;
        }
      }

      &_submit {
        // font-size: 16px;
        // line-height: 24px;
        // color: $sport-event-details-betslip-content-increment-button-text;
        // background: $sport-event-details-betslip-button;
        // border-radius: 5px;
        // border: none;
        // grid-column-start: 3;
        // grid-column-end: 5;
        // padding: 8px 0;
        // margin-top: 10px;
        // position: relative;
        width: 100%;
        height: 72px;
        background: #000000;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        position: relative;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          background-color: $complementary-grey;
        }
      }

      &_error {
        font-size: 12px;
        line-height: 18px;
        color: $error-red;
      }
    }

    &-nodata {
      padding-top: 47px;

      &-image {
        margin: 0 auto 30px auto;
        display: block;
      }

      &-text {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        span {
          color:#000;
          text-align: center;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: block;
          margin-bottom: 10px;
        }
      }
      &-button{
        margin-top: 29px;
        padding: 9px 20px;
        justify-content: center;
        align-items: center;
        width: 100%;
        display: flex;
        color: #00A826;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        border-radius: 5px;
        background: #F3FFF6;
        cursor: pointer;
      }
    }
  }

  div.betslip_message {
    position: absolute;
    bottom: auto;
    top: 44px;
    width: 90%;
    margin: 0 auto;

    &-error {
      .MuiAlert-filledError {
        background: $sport-event-details-betslip-error;
        border-radius: 10px;
        padding: 15px 20px;
      }
    }
  }

  &.blackout {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // background: rgba(16, 27, 68, 0.5);
      z-index: 100;
      // transition: all 2s;
    }
  }

  &.loading {
    .MuiCircularProgress-colorPrimary {
      margin-top: 255px;
      position: absolute;
      left: calc(50% - 20px);
      z-index: 1000;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(16, 27, 68, 0.5);
      z-index: 100;
      // transition: all 2s;
    }
  }
}


input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


@media screen and (max-width: 1300px) {
  .betslip__body {
    color: $sport-event-details-betslip-body-text-mobile;
  }

  .betslip__header {
    background: $sport-event-details-betslip-header-bg-mobile;
    color: $sport-event-details-betslip-header-text;
  }
}

@media screen and (min-width: 1300px) {
  .betslip__body-details-name {
    color: $sport-event-details-betslip-content-name-desktop;
  }

  .betslip__body {
    color: $sport-event-details-betslip-body-text-desktop;
  }
}

.place-bet_input-odd-pluss{
  position: absolute;
  right: 7px;
  top: 6px;
  background: #000;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65%;
  border-radius: 3px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.place-bet_input-odd-minuss{
  position: absolute;
  left: 7px;
  background: #000;
  top: 6px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65%;
  border-radius: 3px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;

}


@media screen and (min-width: 1024px) {
  .betSlipPopUp{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  .betslip{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;

    &__header {
      padding: 8px 10px;
      height: 60px;
      background: #00A826;
      border-radius: 10px 10px 0 0;
      display: flex;
      padding: 0 31px;
      &-name{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-left: 10px;
      }
    }

    &__body{
      height: auto;
      padding: 0 31px 50px 31px;
      border-radius: 20px;
      overflow-y: auto;
    }
    
    .wrapper{
      right: 3%;
      bottom: 0;
      border-radius: 20px 20px 0 0;
      width: 430px;
      
    }
  }
  .openLoadingDesktop{
    bottom: 0;
    transition: all 0.6s;
    animation: openLoadingDesktop .4s ease;
    &:before {
      content: '';
      width: 100%;
      // height: 100%;
      border: none;
      // background-color: rgba(16, 27, 68, 0.4);
      display: inline-block;
      position: absolute;
      top: 3px;
      opacity: 1;
      transition: all 0.8s;
      // animation: openBet .4s ease;
    }
  }
  .openDesk{
    bottom: 0;
    transition: all 0.6s;
    animation: openBetDesk .4s ease;
  
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      border: none;
      // background-color: rgba(16, 27, 68, 0.4);
      display: inline-block;
      position: absolute;
      top: 3px;
      opacity: 1;
      transition: all 0.8s;
      // animation: openBet .4s ease;
    }
  }
  
  
  .closeDesk {
    animation: closeBetDesk .6s ease;
    transition: all .6s;
    // bottom: -100%;

    &:before {
      content: '';
      width: 100%;
      height: 0%;
      opacity: 0;
      display: inline-block;
    }
  }
  
  .betSlipCondirm-wrapper{
    right: 3%;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    width: 430px;
    animation: openConfirmDesk .4s ease
  }
  .betslip-showOdds{
    // height: 149px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    margin-bottom: 9px;
    padding: 15px 15px 19px 19px;
    &-title{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.0015em;
      color: #000000;
    }
    &-oddsBox{
      display: flex;
      width: 100%;
      height: 45px;
      margin-bottom: 3px;
      &-team{
        background: #F7F7F7;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.0015em;
        color: #000000;
        width: 100%;
      }
      &-oddsItems{
        display: flex;
        justify-content: space-between;
        width: 200px;
        height: 100%;
  
        &-itemBack{
          width: 50%;
          margin: 0 1.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #72BBEF;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
        }
        &-itemLay{
          width: 50%;
          margin: 0 1.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #FAA9BA;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
  }
  .closeConfirm{
    animation: closeConfirmBet .5s ease;
  }
  .betSlipFoot{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .betSlipFooter{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    display: flex;
    justify-content: center;
  }
  .betSlipFooterAddMore{
    width: 80%;
    height: 34px;
    background: #F3FFF6;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #00A826;
    margin-top: 30px;
    cursor: pointer;
  }
}
@keyframes openBetDesk {
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(0%);
  }
}

@keyframes closeBetDesk {
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(100%);
  }
}

@keyframes openLoadingDesktop {
  0%{
    height: 313px;
    opacity: 0;
  }
  100%{
    height: 600px;
    opacity: 1;
  }
}

@keyframes openConfirmDesk {
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(0%);
  }
}

@keyframes closeConfirmBet {
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(100%);
  }
}


.disabledd {
  visibility: hidden;
}

.checkBoxWrapperBet{
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  transform: scale(.8);
  .cbxx {
    -webkit-perspective: 20;
    perspective: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px;
    border: 2px solid #e8e8eb;
    background: #e8e8eb;
    border-radius: 4px;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    transition: all 0.3s ease;
   }
   
   .cbxx:hover {
    border-color: #000;
   }
   
   .flipp {
    display: block;
    transition: all 0.4s ease;
    transform-style: preserve-3d;
    position: relative;
    width: 20px;
    height: 20px;
   }
   
   #cbxx {
    display: none;
   }
   
   #cbxx:checked + .cbxx {
    border-color: #000;
   }
   
   #cbxx:checked + .flipp {
    transform: rotateY(180deg);
   }
   
   .frontt,
   .backk {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 2px;
   }
   
   .frontt {
    background: #fff;
    z-index: 1;
   }
   
   .backk {
    transform: rotateY(180deg);
    background: #000;
    text-align: center;
    color: #fff;
    line-height: 20px;
    box-shadow: 0 0 0 1px #000;
   }
   
   .backk svg {
    margin: auto;
    fill: none;
   }
   
   .backk svg path {
    stroke: #fff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
   }   
}

.confirmCountDown{
  position: absolute;
  width: 24px;
  height: 20px;
  background: #FFFFFF;
  border-radius: 50px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #11DB3F;
}
