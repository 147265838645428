@import './phone.scss';
@import '../../../static/scss/variables.scss';

@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

@media (min-width: 1024px) {
  .user-time-settings {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: $user-settings-color;
    width: 100%;
    height: 100%;
    z-index: 16;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    .user-time-settings__content {
      width: 500px;
      padding-bottom: 28px;
      border-radius: 20px;
      background-color: $user-settings-bg-secondary;
      transition: all 0.3s;
      animation: openDetailsDesk 0.3s linear forwards;
      display: flex;
      align-items: center;
      flex-direction: column;

      .user-time-settings__content-header {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        border-radius: 20px 20px 0 0;
        background-color: $user-settings-header-bg;
        span {
          font-weight: 500;
          font-size: 22px;
          color: $user-settings-button-color;
        }
        img {
          cursor: pointer;
        }
      }
    }
    .user-time-settings__content-img {
      width: 26px;
      height: 33px;
      margin-top: 32px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .user-time-settings__content-date {
      font-weight: 500;
      font-size: 18px;
      margin-top: 20px;
    }
    .user-time-settings__content-time {
      font-weight: 500;
      font-size: 13px;
      margin-top: 12px;
    }
    .user-time-settings__customize {
      background-color: $user-settings-bg-secondary;
      width: calc(100% - 46px);
      padding: 20px 20px 25px 20px;
      border: 1px solid $user-settings-bg;
      box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.03);
      border-radius: 10px;
      margin-top: 20px;
    }
    .user-time-settings__customize-dropdown {
      margin: 10px 0;
      height: 42px;
      border: 1px solid $user-settings-color;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      cursor: pointer;
      position: relative;
      background-color: $user-settings-bg-secondary;
      ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .user-time-settings__customize-dropdown-arrow {
        transition: all 0.3s;
        img {
          width: 10px;
          height: 10px;
        }
        &.active {
          transform: rotateX(-180deg);
        }
      }
      .user-time-settings__customize-dropdown-items {
        width: 100%;
        background-color: $user-settings-bg;
        padding: 5px;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 42px;
        transform: translateY(-50%) scaleY(0);
        transition: all 0.3s;
        overflow: hidden;
        opacity: 0;
        border-radius: 5px;
        li {
          height: 42px;
          display: flex;
          align-items: center;
          background-color: $user-settings-bg-secondary;
          border-radius: 5px;
          padding: 0 10px;
          margin: 2px 0;
          transition: all 0.3s;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          &:hover {
            background-color: $user-settings-button-hover;
          }
          &.chosen {
            background-color: $user-settings-header-bg;
            color: $user-settings-button-color;
          }
        }
        &.show-dropdown {
          z-index: 1;
          transform: translateY(0%) scaleY(1);
          height: auto;
          opacity: 1;
        }
      }
    }

    .user-time-settings__content-button {
      background-color: $change-password-button-bg;
      color: $change-password-button-color;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      width: 100%;
      line-height: 0;
      height: 48px;
      border-radius: 5px;
      cursor: pointer;
      &-title {
       margin-top: 4px;
      }
      img {
        width: 15px;
        height: auto;
        margin-left: 15px;
      }
      //&.disabled {
      //  background-color: $change-password-button-bg;
      //}
      }
    .MuiSnackbar-anchorOriginTopCenter {
      transform: translate(-50%, 100px);
    }
  }
  .user-time-settings__content.close {
    animation: closeDetailsDesk 0.3s linear forwards;
  }
  @keyframes openDetailsDesk {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes closeDetailsDesk {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
}
