@import "phone.scss";

.menu-section__list-item--parent {
  &>.menu-section__list-item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    // margin-top: 12px;
    // margin-bottom: 12px;

    &:after {
      content: url("../../../../../static/images/sidemenu/arrow.svg");
      opacity: 1;
      transition: all 0.2s;
      padding-left: unset;
        background-color: $leftside-menu-arrow-bg;
        width: 21.5px;
      height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
    }

    &:hover {
      cursor: pointer;
    }

    &--active {
      font-weight: 700;
      background-color: $leftside-menu-subContent-bg;
      padding: 12px 15px;
      margin: 0;
      border-radius: 5px;

      &:after {
        transform: scale(1, -1);
        margin-bottom: -8px;
        opacity: 1;
        padding: unset;
        background-color: $leftside-menu-arrow-bg;
        width: 21.5px;
        height: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
      }

      &+.menu-section__list-item-children {
        background-color: $leftside-menu-subContent-bg;
        list-style-type: none;
        padding-left: 0;
        position: relative;
        z-index: 2;
        padding: 0 15px 3px 15px;

        &>li {
          padding-bottom: unset;
          background-color: $leftside-menu-content-event-menu-bg;
          border-radius: 5px;
          margin-bottom: 5px;
          

          &>.menu-section__list-item-title {
            padding-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            &.full {
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:after {
                content: url("../../../../../static/images/sidemenu/subArrow.svg");
                opacity: 1;
                transition: all 0.2s;
                padding-left: 12px;
                padding: unset;
                background-color: $leftside-menu-subArrow-bg;
                width: 21.5px;
                height: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 53px;
              }

              &.menu-section__list-item-title--active {
                padding-bottom: 12px;
                padding-top: 13px;
                // padding: unset;
              }
            }

            &--active {
              &:after {
                transform: scale(1, -1);
                margin-bottom: -8px;
                opacity: 1;
                
              }

              &+.menu-section__list-item-children {
                list-style-type: none;
                padding-left: 0;
                padding: 10px;
                border-radius: 5px;

                li {
                  .menu-section__list-item-title {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 15px;
                    background-color: $leftside-menu-subContent-bg;
                    margin-bottom: 5px;
                    border-radius: 5px;

                    &:hover {
                      background-color: $leftside-menu-content-event-menu-active-item-bg;
                      padding-top: 10px;
                      padding-bottom: 10px;
                      position: relative;

                      &:before {
                        content: '';
                        width: 2px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        background-color: $leftside-menu-content-event-menu-active-line;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
