@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';

.user-bonus {
  width: 100%;
  height: 100%;
  padding: 55px;
  background: transparent;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .invisible {
    display: none;
  }

  .user-bonus__user {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    color: $bonus-page-color;
    text-align: center;

    &-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      align-items: center;
    }

    &-hide {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    &-close {
      width: 21px;
      height: 21px;
      cursor: pointer;
    }

    &-menu {
      display: flex;
      align-items: center;
      justify-content: center;

      &-screen, &-log-out {
        height: 23px;
        width: 23px;
        cursor: pointer;
        &.minimize {
          background-color: white;
          border-radius: 50%;
          padding: 9px;
        }
      }
      &-log-out {
        position: absolute;
        right: 0;
      }

      &-screen,  &-hide {
        display: none;
      }

      &-img {
        height: 60px;
        width: 60px;
        margin-right: 16px;
      }
    }

    &-info {
      color: $bonus-page-color;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-name {
        font-weight: 500;
        font-size: 20px;
        display: flex;
        align-items: center;
        &-img {
          width: 16px;
          height: 22px;
          display: inline-block;
          margin-left: 10px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      &-id {
        margin-top: 5px;
      }
    }

    &-amound {
      height: 44px;
      width: 100%;
      color: $bonus-page-odds-color-dark;
      background-color: $bonus-page-items-bg;
      font-size: 14px;
      font-weight: 600;
      margin-top: 15px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4px 0 20px;
      &-button {
        width: 114px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $bonus-page-color;
        background-color: $bonus-page-values-bg-secondary;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .user-bonus__wallets {
    display: flex;
    justify-content: center;
  }

  .user-bonus__wallet {
    background: $bonus-page-items-bg;
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    width: calc(100% - 30px);
    height: 170px;
    padding: 22px;

    &-control-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      &-title {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        font-size: 16px;
        color: #15161B;
        position: relative;
        &-with-info {
          position: relative;
          max-width: 130px;
        }

        img {
          position: absolute;
          right: 0;
          top: 0;
          width: 17px;
          height: 17px;
          cursor: pointer;
        }
        .sub-title {
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-top: 6px;
        }
      }

      &-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        &-reload {
          width: 22px;
          height: 31px;
          margin-right: 15px;
        }
        $width: 60px;
        $height: 27px;
        &-toggle {
          cursor: pointer;
            position: relative;
          .slider {
            display: inline-block;
            width: $width;
            height: $height;
            background-color:  $bonus-page-toggle-bg-inactive;
            border-radius: calc(#{$height} / 2);
            position: relative;
            transition: 0.3s all ease-in-out;
            cursor: pointer;
            margin-top: 2px;
          }
          .slider-text {
            font-weight: 500;
            font-size: 13px;
            line-height: 22px;
            color: #FFFFFF;
            position: absolute;
            top: 5px;
            left: 7px;
            font-weight: 500;
            &.off {
              left: 29px;
              color: #000000;
            }
          }
          .slider::after {
            content: "";
            display: inline-block;
            width: #{$height};
            height: #{$height};
            background-color: $bonus-page-toggle-bg-secondary;
            background-image: url("../../../../static/images/byBurger.svg");
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: calc(#{$height} / 2);
            position: absolute;
            top: 0;
            transform: translateX(0);
            box-shadow: 10px 0 calc(10px * 4) rgba(0, 0, 0, 0.1);
            transition: 0.3s all ease-in-out, background-size unset;
            z-index: 1;
          }
          .slider.active-loading::after {
            content: "";
            display: inline-block;
            width: #{$height};
            height: #{$height};
            background-color:  $bonus-page-toggle-bg-secondary;
            background-image: url("../../../../static/images/bonus/loading.svg");
            background-size: 35px;
            //background-size: 10px;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: calc(#{$height} / 2);
            position: absolute;
            top: 0;
            transform: translateX(0);
            box-shadow: 10px 0 calc(10px * 4) rgba(0, 0, 0, 0.1);
            transition: 0.3s all ease-in-out, background-size 0.1ms 0.3s;
          }
          .switch input[type="checkbox"]:checked + .slider {
            background-color: $bonus-page-toggle-bg-active;
          }
          .switch input[type="checkbox"]:checked + .slider::after {
            transform: translateX(calc(#{$width} - #{$height}));
            box-shadow: calc(10px * -1) 0 calc(10px * 4) rgba(0, 0, 0, 0.1);
            background-color: $bonus-page-toggle-bg;
          }
          .switch input[type="checkbox"] {
            display: none;
          }
          .switch input[type="checkbox"]:active + .slider::after {
            width: $width;
          }
          .switch input[type="checkbox"]:checked:active + .slider::after {
            transform: translateX(0.5px);
            background-color: $bonus-page-toggle-bg;
          }
        }
        &-toggle.not-clickable {
          .slider {
            cursor: none;
          }
          pointer-events: none;
        }
      }
    }

    &-odds-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 10px
    }

    .user-bonus__wallet-odds {
      width: calc((100% - 17px) / 2);
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 10px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;

      span:last-child {
        font-weight: 400;
        font-size: 14px;
        margin-top: 5px;
      }

      &.credit {
        background: $bonus-page-odds-bg;
        color: $bonus-page-odds-color;
      }

      &.exposure {
        background: $bonus-page-odds-bg-secondary;
        color: $bonus-page-odds-color;
      }
    }
  }
  .user-bonus__wallet:not(:last-child) {
    margin-right: 15px;
  }

  .user-bonus__side-menu {
    background: $bonus-page-items-bg;
    //background: red;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 25px 25px 11px 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;

    &-item {
      height: 62px;
      background: $bonus-page-items-bg-secondary;
      border-radius: 10px;
      width: calc((100% - 20px) / 3);
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      padding: 10px 15px;
      transition: all 0.2s;
      cursor: pointer;

      &-img {
        width: 42px;
        height: 42px;
        margin-right: 15px;
        background: $bonus-page-side-menu-img-bg;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &-icon {
          width: 18px;
        }
      }

      &-title {
        font-weight: 500;
        font-size: 16px;
      }

      &:hover {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      }
      &.sign-out {
        display: none;
      }
    }
  }


  .img-button {
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .active {
    animation: rotate-refresh 0.5s infinite linear;
  }

  @keyframes rotate-refresh {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.user-bonus.with-details {
  overflow: unset;
}

.sideBar-desktop {
  max-width: 430px;
  background-color: white;
  padding: unset;
  overflow: auto;
  &-container {
    background: $bonus-page-bg-secondary;
    padding: 20px 0 20px 20px;
    height: 430px;
    .user-bonus__wallets {
      justify-content: start;
      overflow: scroll;
      width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .user-bonus__wallet {
      width: 85%;
      min-width: 318px;
      padding: 20px;
      margin-right: 15px;
    }
    .user-bonus__side-menu {
      margin-top: 15px;
      padding: 23px 20px 30px 20px;
      width: calc(100% - 20px);
      &-item {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        &.sign-out {
          display: flex;
        }

        &:hover {
          box-shadow: unset;
        }
      }
    }
    .user-bonus__user {
      width: calc(100% - 20px);
    }
  }


}

.sideBar-mobile {
  //max-width: 430px;
  background-color: white;
  padding: unset ;
  overflow: auto;
  width: 100%;
  &-container {
    padding: 20px 0 20px 20px;
    background: $bonus-page-bg-secondary;
    height: 430px;
  }
}
.page-mobile {
  position: absolute;
  top: 60px;
  overflow-y: auto;
  padding: unset !important;
  z-index: 3;
  background-color: $bonus-page-bg;
  &-container {
    padding: 20px 0 20px 20px;
    background: $bonus-page-bg-secondary;
    height: 430px;
  }
}


@media screen and (max-width: 851px) {
  .user-bonus {
    padding: unset;
    .user-bonus__user {
      margin-bottom: 10px;
      padding-right: 20px;
      position: relative;
      &-hide {
        position: absolute;
        left: 10px;
        top: 25px;
      }
      &-close {
        display: none;
      }
      &-menu {
        &-screen {
          display: none;
        }
        &-img {
          img {
            height: 60px;
          }
        }
      }
    }
    .user-bonus__wallets {
      justify-content: start;
      overflow: scroll;
      width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .user-bonus__wallet {
      width: 85%;
      min-width: 318px;
      padding: 20px;
      margin-right: 15px;
    }
    .user-bonus__side-menu {
      margin-top: 15px;
      padding: 23px 20px 70px 20px;
      width: calc(100% - 20px);
      &-item {
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        &.sign-out {
          display: flex;
        }

        &:hover {
          box-shadow: unset;
        }
      }
    }
  }
}
