@import '../../../../static/scss/variables.scss';
@import '../../../../static/scss/media.scss';

@include respond(Medium) {
    div.menu-section {
        padding: 18px 12px;
        background-color: $leftside-menu-content-bg;
        &__title {
            width: auto;
            color: $leftside-menu-whiteText;
        }
    }
    .menu-section__list{
        background-color: $leftside-menu-content-event-menu-bg;
        background: #FCFCFC;
        border-radius: 0px 0px 10px 10px;
        padding: 12px !important;
        &-item{
            background: #F5F5F5;
            border-radius: 5px;
            margin-bottom: 5px;
            height: 40px;
            // padding-top: 0;
           
        }
    }
    .menu-section__title--active:after{
        margin-bottom: unset !important;
    }
}