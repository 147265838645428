@import "phone.scss";

.menu-section__list-item--parent {
  &>.menu-section__list-item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 12px;
    margin-bottom: 12px;

    &:after {
      content: url("../../../../../static/images/sidemenu/arrow.svg");
      opacity: 0.6;
      transition: all 0.2s;
      padding-left: 12px;
    }

    &:hover {
      cursor: pointer;
    }

    &--active {
      font-weight: 700;
      background-color: $leftside-menu-content-bg;
      padding: 12px 15px;
      margin: 0;

      &:after {
        transform: scale(1, -1);
        margin-bottom: -8px;
        opacity: 1;
      }

      &+.menu-section__list-item-children {
        background-color: $leftside-menu-content-event-menu-bg;
        list-style-type: none;
        padding-left: 0;

        &>li {
          // &:first-child {
          //   padding-top: 18px;
          // }
          padding-bottom: 18px;

          &>.menu-section__list-item-title {
            padding-left: 30px;

            &.full {
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:after {
                content: url("../../../../../static/images/sidemenu/arrow.svg");
                opacity: 0.6;
                transition: all 0.2s;
                padding-left: 12px;
              }

              &.menu-section__list-item-title--active {
                padding-bottom: 12px;
              }
            }

            &--active {
              &:after {
                transform: scale(1, -1);
                margin-bottom: -8px;
                opacity: 1;
              }

              &+.menu-section__list-item-children {
                list-style-type: none;
                padding-left: 0;

                li {
                  .menu-section__list-item-title {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 53px;

                    &:hover {
                      background-color: $leftside-menu-content-event-menu-active-item-bg;
                      padding-top: 10px;
                      padding-bottom: 10px;
                      position: relative;

                      &:before {
                        content: '';
                        width: 2px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        background-color: $leftside-menu-content-event-menu-active-line;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}