@import './variables.scss';

button.white-button {
  background: $common-white;
  color: $main-text-color;
  border: 1px solid $black-text;
  box-shadow: none;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14;

  &:hover {
    background: $common-white;
  }
}