@import "phone.scss";

.menu-section {
  font-family: Source Sans Pro, sans-serif;
  color: $leftside-menu-text;
  background: #FCFCFC;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 0px 0px 10px 10px;

  &:not(:last-child) {
    margin-bottom: 3px;
  }

  &>.menu-section__title {
    padding-left: 42px;
    border-radius: 10px 10px 0px 0px;
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.02em;
    padding: 10px 15px;
    background: $leftside-menu-header-bg;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    //sticky title
    position: sticky;
    top: 0;
    width: 319px;
    z-index: 110;

    &:after {
      content: url("../../../../static/images/header/menuCloseArrowDown.svg");
      transition: all 0.2s;
      position: relative;
      top: 3px;
    }

    &:hover {
      cursor: pointer;
    }

    &--active {
      &:after {
        transform: scale(1, -1);
        margin-bottom: 0;
        position: relative;
        top: -3px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 12px;
    list-style-type: none;
    background-color: white;

    &-item {
      display: block;
      align-items: center;
      position: relative;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      min-height: 40px;
      color: #292B2D;
      // padding-top: 12px !important;
      height: unset;
      background-color: #f5f5f5;
      border-radius: 5px;
      margin-bottom: 5px;
      &--parent{
        // padding-top: 1px !important;
      }
      .trendingItem{
          position: absolute;
          right: 12px;
          top: 12px;
      }
      a{
        text-decoration: none;
      }

      // &:not(:first-child):not(.menu-section__list-item--parent) {
      //   padding-top: 12px;
      // }

      // &:not(.menu-section__list-item--parent):first-child {
      //   padding-top: 10px;
      // }

      // &:not(.menu-section__list-item--parent):last-child {
      //   padding-bottom: 30px;
      // }

      &-title {
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
        display: flex;
        min-height: 40px;
        justify-content: space-between;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
