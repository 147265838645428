@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';

@media (max-width: 1024px) {
  .change-password {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    padding: 30px 20px !important;
    color: $change-password-color;

    &__title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__text {
      font-weight: 400;
      margin-bottom: 40px;
    }

    .MuiInputBase-root {
      background-color: unset;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
    }

    .MuiButton-contained {
      margin-top: 18px;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
      border-radius: 5px;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      display: none;
    }

    .change-password__form {
      padding: 25px;
      background-color: $change-password-bg;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
    }
    .hidden-custom input[name="password"] {
      -webkit-text-security: unset !important;
    }
    .change-password__form-item {
      margin-bottom: 12px;
      &-title {
        font-weight: 700;
        margin-bottom: 10px;
      }
      .formik-field {
        background: unset;
        margin: unset;
        max-width: unset;
        input {
          height: 50px;
          padding: 0 12% 0 18px;
        }
        fieldset legend {
          display: none;
        }
        fieldset {
          height: 50px;
          top: 0;
        }
        label {
          color: $change-password-color;
        }
        img {
          top: 14px;
        }
        .MuiFormHelperText-root {
          color: $account-detail-changePassword-validationError;
        }
      }
    }
    .change-password__form-button {
      background-color: $change-password-button-bg-active;
      color: $change-password-button-color;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      width: 100%;
      line-height: 0;
      height: 50px;
      img {
        width: 15px;
        height: auto;
        margin-left: 15px;
      }
      &.disabled {
        background-color: $change-password-button-bg;
      }
    }
    .change-password__privacy-policy {
      font-weight: 400;
      font-size: 13px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      .MuiCheckbox-colorSecondary {
        margin: unset;
        color: black;
        padding: unset;
        margin-right: 15px;
      }
      &-link {
        color: $change-password-term-color;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
