@import "phone.scss";

.game {
  background: $primary-background;
  min-height: calc(100vh - 98px);

  &-table {
    padding: 15px 0 15px 0;

    &__header {
      font-family: Source Sans Pro, sans-serif;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $event-list-page-title-text;
      display: grid;
      grid-template-columns: 40% 60%;
      position: relative;
      z-index: 1;
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        top: -15px;
        left: 0;
        width: 101%;
        background: $primary-background;
        height: 15px;
      }

      &-title {
        padding-left: 20px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #000000;
      }

      &-result {
        text-align: center;
        display: flex;
        padding-right: 15px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #000000;

        div {
          width: 33%;
        }
      }
    }

    &__body {
      display: grid;
      
      margin-top: 10px;

      &-noData {
        font-family: Source Sans Pro, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background-color: $event-list-page-content-item-text;
        padding: 15px 10px;
      }

      &-tr {
        font-family: Source Sans Pro, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        display: grid;
        grid-template-columns: 40% 60%;
        grid-template-rows: 52px;
        height: 65px;
        &.sport {
          grid-template-columns: 40% 60%;
        }
      }

      &-td {
        background-color: $event-list-page-content-item-bg;
      }

      &-time {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;

        .live {
          color: $event-list-page-content-item-live-event;
          font-weight: 600;
        }

        .wrapper {
          display: flex;
          flex-direction: column;
          text-align: center;

          .label,
          .date {
            color: $event-list-page-content-item-future-event-day;
          }
        }
      }

      &-eventName {

        padding-left: 15px;
        display: flex;
        align-items: center;
        height: 65px;
        border-radius: 5px 0 0 5px;
        a {
          text-decoration: none;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          &:hover {
            color: $event-list-page-content-item-active-text;
            text-decoration: underline;
          }
        }
      }

      &-icons {
        display: grid;
        grid-template-columns: 16px 10px 18px 8px 19px 17px;
        grid-column-gap: 28px;
        align-items: center;
        padding: 0 25px 0 10px;

        span {
          display: flex;
          align-items: center;

          span.icon-premium,
          span.icon-cards-gaming {
            font-size: 14px;
          }

          span.icon-egaming {
            font-size: 12px;
          }

          span.icon-F,
          span.icon-BM {
            font-size: 10px;
          }
        }
      }

      &-result {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 10px 15px 10px 0;
        color: $event-list-page-content-item-bg;
        height: 65px;
        border-radius: 0 5px 5px 0;
        &--none {
          display: none;
        }

        &-cell {
          .gameTableItemBack{


          span {
            width: calc(100% / 2);
            height: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:first-child {
              color: $event-list-page-content-item-back-lay-text;
              background: $event-list-page-content-item-back-bg;
              font-weight: bold;

            }
          }
          }
          .gameTableItemLay{


            span {
              width: calc(100% / 2);
              height: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              &:first-child {
                color: $event-list-page-content-item-back-lay-text;
                background: $event-list-page-content-item-lay-bg;
                font-weight: bold;
              }
            }
            }

          &.lock {
            position: relative;

            span {
              background: $event-list-page-content-item-suspended-bg;
              color: $event-list-page-content-item-suspended-text;
            }

            &:before {
              content: url('../../../../static/images/gameTable/lock.svg');
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              height: 20px;
            }
          }
        }

        &-error {
          color: $event-list-page-content-item-suspended-text;
          background: $event-list-page-content-item-suspended-bg;
          border-radius: 5px;
          grid-column-start: 1;
          grid-column-end: 4;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media all and (max-width: 1540px) {
  .game-table__body-tr {
    grid-template-columns: 40% 60%;
    height: 65px;

    &.sport {
      grid-template-columns: 40% 60%;
    }
  }

  .game-table__body-icons {
    grid-column-gap: 18px;
    padding-left: unset;
  }

  .game-table__body-td.game-table__body-eventName a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;

  }
}

@media all and (max-width: 1240px) {
  .game-table__header {
    grid-template-columns: 40% 60%;
  }

  .game-table__body-tr {
    height: 65px;
    grid-template-columns: 40% 60%;
  }

  .game-table__body-icons {
    grid-column-gap: 18px;
    padding-left: 10px;
  }
}


@media all and (min-width: 1024px) {
 .eventCard{
  margin-bottom: unset;
   &_live{
     position: absolute;
     right: 5px;
     top: 5px;
     height: 15px;
     margin-left: 20px;
   }
 }
 .game-table__body{

   grid-template-columns: 1fr 1fr 1fr;
   grid-row-gap: 10px;
   grid-column-gap: 7px;
  }
}
