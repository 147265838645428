@import "phone";
@import '../../../static/scss/variables.scss';

$light-blue: $common-theme-color;
$font-main: Source Sans Pro;

.global-rules-popup {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  max-width: 100vw;
  padding: 10px;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  &-content {
    background: $light-blue;
    color: $black-text;
    font-family: $font-main;
    width: 100%;
    max-width: 1660px;
    transform: scale(0.5);
    transition: 0.4s all;
    overflow: auto;
    max-height: 900px;
    height: 100%;

    &.active {
      transform: scale(1);
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    background: $common-theme-color;
    padding: 9px 20px;

    &__text {
      display: flex;
      align-items: center;

      span {
        padding-left: 8px;
        font-weight: 600;
        font-size: 25px;
      }
    }

    img.globalRulesInfoIcon,
    img.globalRulesClose {
      position: unset;
      height: 25px;
      width: 25px;
    }

    &__close {
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
      transition: 0.3s;
    }

    &__close:hover {
      opacity: 0.6;
    }
  }

  &-container {
    padding: 20px 20px 6px 20px;
    display: flex;
  }

  &-container__rules-list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 290px;
  }

  &-container__rules-list-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: 0.3s;
  }

  @media (min-width: 851px) {
    .global-rules-popup-container__rules-list-item {
      background: $common-theme-color;
    }
  }

  &-container__rules-list-item:not(:last-child) {
    border-bottom: 1px solid #BBBBBB;
  }

  &-container__rules-list-item-title {
    font-weight: 400;
    font-size: 22px;
    margin: 0;
  }

  &-container__desktop-rules-text-wrapper {
    width: 100%;
  }

  &-container__desktop-rules-text-title {
    margin: 0;
    font-weight: 400;
    font-size: 33px;
    background: $common-theme-color;
    padding: 2px 25px;
  }

  &-container__desktop-rules-text {
    padding: 30px 25px;
    color: $black-text;
    font-size: 21px;
  }

  article {
    font-size: 16px;
    padding: 30px 0 30px 25px;
    max-height: 750px;
    overflow: auto;

    p {
      color: $black-text;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}