@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';

@include respond(Medium) {

  .home-menu-tabs {
    display: flex;
    height: 51px;
    width: auto;
    border: 1px solid $black-text;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 14px;

    &__scroll-y {
      position: relative;
      display: flex;
      overflow-y: auto;
      bottom: -1px;
      flex-grow: 1;

      .home-menu-tabs__scroll-x {
        position: relative;
        display: flex;
        overflow-x: auto;

        .home-menu-tabs__tab {
          display: flex;
          position: relative;
          border-bottom: 0;
          align-items: center;
          justify-content: center;
          padding-left: 10px;
          padding-right: 10px;
          margin-left: 23px;
          white-space: nowrap;
          text-decoration: none;
          color: $sub-nav-menu-text;
        }

        .home-menu-tabs__tab.active {
          border-bottom: 2px solid $common-theme-color;
          color: $common-theme-color;
        }

        .home-menu-tabs__tab:hover {
          cursor: pointer;
        }

        .home-menu-tabs__tab:first-child {
          margin-left: 0;
        }

        &::-webkit-scrollbar {
          /* chrome based */
          width: 0px;
          /* ширина scrollbar'a */
          height: 0px;
          background: transparent;
          /* опционально */
          display: none;
        }
      }

      &::-webkit-scrollbar {
        /* chrome based */
        width: 0px;
        /* ширина scrollbar'a */
        height: 0px;
        background: transparent;
        /* опционально */
        display: none;
      }
    }
  }

  .home-menu-tabs.browser {
    display: none;
  }

  .home-menu-tabs.mobile {
    border: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    background-color: $nav-menu-background;
    color: #fff;
    border-radius: 0;

    top: 0;
    left: 0;
    width: 100%;

    .home-menu-tabs__scroll-y {
      bottom: 0;
    }

    .home-menu-tabs__scroll-y .home-menu-tabs__scroll-x .home-menu-tabs__tab.active {
      color: $common-white;
      border-bottom: 2px solid $sub-nav-menu-activeLine;
    }
  }

  ///
  div.home-menu {
    position: static;
    width: 100%;
    height: 35px;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    &__item {
      display: flex;
      justify-content: center;
      position: relative;
    }

    .home-menu__link {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      color: #BFC4D8;
      padding: 8px 20px;
      display: inline-block;
      text-decoration: none;
      white-space: nowrap;

      &.show {
        color: $common-white;

        &:before {
          content: '';
          width: 100%;
          height: 3px;
          background-color: $sub-nav-menu-activeLine;
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 3px 3px 0 0;
        }
      }
    }

    &.active {
      color: $common-white;

      &:before {
        content: '';
        width: 100%;
        height: 3px;
        background-color: $sub-nav-menu-activeLine;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 3px 3px 0 0;
      }
    }
  }
}