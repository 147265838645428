@import "phone.scss";

.menu-section {
  font-family: Source Sans Pro, sans-serif;
  color: $leftside-menu-text;

  &:not(:last-child) {
    margin-bottom: 3px;
  }

  &>.menu-section__title {
    padding-left: 42px;
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.02em;
    padding: 10px 15px;
    background: $leftside-menu-header-bg;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: url("../../../../static/images/sidemenu/arrow.svg");
      transition: all 0.2s;
    }

    &:hover {
      cursor: pointer;
    }

    &--active {
      &:after {
        transform: scale(1, -1);
        margin-bottom: -8px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &-item {
      display: block;
      height: auto;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      
      // &:not(:first-child):not(.menu-section__list-item--parent) {
      //   padding-top: 12px;
      // }

      // &:not(.menu-section__list-item--parent):first-child {
      //   padding-top: 10px;
      // }

      // &:not(.menu-section__list-item--parent):last-child {
      //   padding-bottom: 30px;
      // }

      &-title {
        padding-left: 15px;
        padding-right: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}