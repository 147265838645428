@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

@media all and (max-width: 768px) {
  div.grouped-games {
    display: flex;
    width: 100%;
    flex-direction: column;
    &__header {
      display: none;
    }
    &__body {
      img {
        border-radius: 5px;
      }
      &_img-body{
        background-size: auto 100%;
      }
    }
  }
}
@include respond(Small) {
  div.grouped-games {
      &__body {
        grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
        grid-row-gap: 10px;
      }
  }
}
@media all and (max-width: 375px) {
  div.grouped-games {
    &__body {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      &_item {
        height: 100px;
        border-radius: 5px;
      }
    }
  }
}