@import '../../static/scss/variables.scss';

.sing-up {
  .sing-up_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0, 1) 60%, rgba(0,0,0,0.5) 100% ) !important;
    z-index: 555;

    .sing-up {
      width: 100%;
      height: auto;
      max-width: 550px;
      background: linear-gradient(to bottom, #00A826 40%, #f2f2f2 0%);
      border-radius: 30px 30px 0 0;
      position: absolute;
      animation: openPopUp .6s ease-in-out forwards;
      padding: 15px 15px 0px;
      z-index: 100000;
      &.closed{
        animation: closePopUp 0.6s ease-in-out forwards;
      }
    }

    @keyframes openPopUp {
      0% {
        bottom: -100%
      }
      100% {
        bottom: 0%
      }
    }

    @keyframes closePopUp {
      0% {
        bottom: 0%
      }
      100% {
        bottom: -100%
      }
    }


    .sing-up-content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .sing-up-closeIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    .sing-up-backIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(180deg);
      background: #000;
      border-radius: 10px;
      padding: 2px 8px;
      height: 20px;
      width: 30px;
    }

    .sing-up-form__content {
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      padding: 29px 33px;
      &-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
      }
      &-otpTitle{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.0015em;
        color: #000000;
      }
      &-otpBox{
        margin-top: 15px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .otpVerification{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #000000;
        }
        .otpResent{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #00A826;
        }
      }
      &-otherTitle{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 10px;
      }
      .password2step{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 10px;
      }
      .otpInputs{
        display: flex;
        gap: 5px;
        .formik-field{
          input{
            display: flex;
            justify-content: center;
            align-items: center;
            // border: 1px solid #000000;
            border-radius: 5px;
            height: 48px;
            text-align: center;
            padding: 0;
          }
        }
      }
      .formik-field {
        //height: 40px;
        height: auto;
        width: 100%;
        margin-bottom: 15px;
        //margin-bottom: 10px;
        max-width: unset;
        input{
          padding: 0 12% 0 3%;
        }

        input {
          height: 40px;
          width: 100%;
          padding: 0 12% 0 3% !important;
        }
      }
    }

    .privacyText{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.001em;
      color: #000000;
    }

    .sing-up-form-header{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 85%;
      &__text {
        margin-top: 15px;
        margin-bottom: 7px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
      }
      &__description {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.0015em;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 18px;
      }
    }

    .sing-up-form__privacy-policy {
      display: flex;
      align-items: flex-start;
      margin-top: 18px;
      margin-bottom: -10px;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.04em;
      color: $login-page-input-bg;

      .MuiCheckbox-colorSecondary.Mui-disabled {
        color: $black-text;
        padding-left: 0;
      }

      p {
        color: $login-page-content-text;
      }

      .sing-up-form__privacy-policy--allocated {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.001em;
        color: #00A826;
      cursor: pointer;
      }
    }
  }

  .formik-field__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 10px;
    top: 7px;
  }

  .sing-up-form__alert {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 15px;
    background-color: $login-page-error;
    top: 0;
    margin-top: 40px;
    width: 95%;
    max-width: 490px;
    z-index: 1000000000;

    & p {
      color: $login-page-content-text;
    }

    // &-close-icon {
    //   cursor: pointer;
    //   color: $login-page-content-text;
    //   position: absolute;
    //   right: 5%;
    // }
  }

  .sing-up-form__log-in-button.arrow::after {
    content: "";
    position: absolute;
    display: block;
    right: 60px;
    width: 15px;
    height: 14px;
    background-image: url(./arrowIcon.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .sing-up-form__log-in-button.loading::after {
    content: "";
    position: absolute;
    display: block;
    right: 60px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    animation: loadingSpinner 1s linear infinite;
  }

  .sing-up-form__log-in-button {
    cursor: pointer;
    height: 40px;
  }

  fieldset legend {
    display: none;
  }

  .MuiFormLabel-root {
    color: #15161B;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 12px) scale(1);
    pointer-events: none;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $black-text;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: $login-page-content-bg;
    display: none;
    padding: 0 8px;
    margin-left: -5px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $login-page-content-active;
  }
}

.sing-up-form__log-in-button {
  cursor: pointer;
  height: 40px;
  background: #000000 !important;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-transform: uppercase;
  color: #F8F9FB !important;
}

.resendOTP {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #0074E3;
}

input:not([value=""]) {
  box-shadow: inset 0 0 0 50px $common-white !important;
}

.sing-up-click-loginn {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000;
  margin-top: 30px;
  .sing-up-click-loginn-span {
    margin-left: 3px;
    color: #00A826;
    cursor: pointer;
    text-decoration: underline;
  }
}

@media (min-width: 810px) {
  .sing-up {
    .sing-up_wrapper {
      background: rgba(0, 0, 0, 0.3) !important;
      .sing-up {
        width: 95%;
        max-width: 490px;
        background: linear-gradient(to bottom, #00A826 40%, #f2f2f2 0%);
        border-radius: 30px;
        padding: 15px 15px 0px;
        overflow: auto;
        animation: none;
        &.closed{
          animation: none;
        }
      }
    }

    .sing-up-closeIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;

      img {
        height: 100%;
      }
    }

    .sing-up-logo {
      background-image: $url-logo-login;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      img {
        height: 100%;
      }
    }

    .sing-up-form__content {
      .formik-field {
        //height: 57px !important;
        height: auto;
        width: 100%;
        margin-bottom: 36px;
        max-width: unset;
        margin-top: unset;

        input {
          height: 57px !important;
          width: 100%;
          box-shadow: inset 0 0 0 50px #fff;
        }
      }
    }

    .sing-up-form__content-text {
      margin-bottom: 10px;
    }

    .sing-up-form__log-in-button {
      cursor: pointer;
      height: 40px;
      background: #000000;
      border-radius: 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      text-transform: uppercase;
      color: #F8F9FB;
    }

    .sing-up-form__log-in-button-icon {
      cursor: pointer;
      position: absolute;
      right: 15px;
    }

    .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(20px, 20px) scale(1);
      pointer-events: none;
    }

    .formik-field__icon {
      position: absolute;
      width: 36px;
      height: 27px;
      right: 10px;
      top: 17px;
    }

    .sing-up-form__privacy-policy {
      display: flex;
      align-items: flex-start;
      padding-right: 15px;
      margin-top: 18px;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 25px;
      letter-spacing: 0.04em;
      color: $login-page-input-bg;

      .MuiCheckbox-colorSecondary.Mui-disabled {
        color: $login-page-input-disabled;
      }

      p {
        color: $login-page-privacy-policy;
      }

      .sing-up-form__privacy-policy--allocated {
        color: $login-page-content-active;
      }
    }


    .sing-up-form__alert {
      display: flex;
      position: absolute;
      width: 100%;
      padding: 15px;
      background-color: $login-page-error;
      top: 0;
      margin-top: 40px;
      width: 95%;
      max-width: 490px;
      z-index: 1000000000;

      & p {
        color: $login-page-content-text;
      }
    }


    .MuiFormLabel-root {
      color: #15161B;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: $login-page-error;
    }

    .MuiOutlinedInput-root.Mui-focused {
      background: $login-page-input-bg;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
    }

    input:not([value=""]) {
      box-shadow: inset 0 0 0 50px $common-white !important;
    }

    input {
      box-shadow: inset 0 0 0 50px $common-white;
    }

    input:active,
    input:focus {
      box-shadow: inset 0 0 0 50px $common-white ;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      background: $login-page-content-bg;
      padding: 0 8px;
      margin-left: -5px;
    }

    .MuiFormLabel-root.Mui-focused {
      color: $login-page-content-active;
    }
  }
}

.wrongOTP{
  position: absolute;
  bottom: 16px;
  color: #ff4057;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.hidden-custom{
  input[name="password"]
  {
    -webkit-text-security: disc;
  }

  input[name="confirmPassword"]
  {
    -webkit-text-security: disc;
  }
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  .sing-up .sing-up_wrapper .sing-up{
    height: 100% !important;
    overflow: auto;
  }
}
@media screen and (max-height: 1024px) {
  .sing-up .sing-up_wrapper .sing-up{
    max-height: 100% !important;
    overflow: auto;
  }
}

@media screen and (max-width: 150px) {
  .sing-up .sing-up_wrapper .sing-up {
    height: 100% !important;
    overflow: auto;
  }
}

@media (min-width: 375px) {
  .sing-up-form__log-in-button.arrow::after {
    right: 90px !important;
  }

  .sing-up-form__log-in-button.loading::after {
    right: 90px !important;
  }
}

@media (min-width: 425px) {
  .sing-up-form__log-in-button.arrow::after {
    right: 110px !important;
  }

  .sing-up-form__log-in-button.loading::after {
    right: 110px !important;
  }
}

@media (min-width: 768px) {
  .sing-up-click-loginn {
    margin-top: 40px;
  }

  .sing-up-form__log-in-button.arrow::after {
    right: 170px !important;
  }

  .sing-up-form__log-in-button.loading::after {
    right: 170px !important;
  }
}

@media (min-width: 1024px) {
  .sing-up-form__log-in-button.arrow::after {
    right: 140px !important;
  }

  .sing-up-form__log-in-button.loading::after {
    right: 140px !important;
  }
}

.signUpWith{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-container{
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
    &-row{
      width: 100%;
      height: 70px;
      background: #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      .iconTitle{
        display: flex;
        align-items: center;
        .title{
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.0015em;
          text-align: left;
          color: #000;
          margin-left: 20px;
        }
      }
    }
  }
  &-redirect{
    margin: 30px 0 20px 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.0015em;
    text-align: center;
    span{
      color: #00A826;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@keyframes animatedText {
  0% {
    opacity: 0.2;
    margin-left: -50px;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes animatedText1 {
  0% {
    opacity: 0.2;
    margin-right: -50px;
  }
  100% {
    opacity: 1;
    margin-right: 0px;
  }
}

.signUpSuccess{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 23px 33px 23px;
  &-title{
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 110% */
    margin-top: 38px;
    margin-bottom: 14px;
  }
  &-descr{
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.021px;
    margin-bottom: 38px;
  }
  &-buttons{
    display: flex;
    justify-content: center;
    gap: 10px;
    &-button{
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.10);
      padding: 7px 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.021px;
      text-transform: uppercase;
      cursor: pointer;
      margin-bottom: 38px;
    }
  }
}

.signUpWhatsAppNext{
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items:center;
  background: #00A826;
  color: #fff;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  // text-transform: uppercase;
  color: #F8F9FB;
  position: relative;
  margin-bottom: 25px;
  &-img{
    width: 33px;
    height: 33px;
    position: absolute;
    left: 17px;
    top: 3px;
  }
}

@media screen and (min-width: 1024px) {
  .signUpWhatsAppNext{
    height: 57px;
    font-size: 18px;
    &-img{
      left: 50px;
      top: 10px;
    }
  }
}

.signUpDivider{
  margin: 7px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  &-line{
    background: linear-gradient(45deg, #00A826 ,transparent);
    height: 1px;
    width: 45%;
    transform: rotate(.35deg);
    &.leftLine{
      transform: rotate(180deg);
    }
  }
  &-text{
    font-size: 20px;
    color: #00A826;
  }
}
