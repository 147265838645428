@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';

  div.game {
    &-table {
      background-color: transparent;
      &__body {
        margin-top: 0;

        &-tr {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: auto;
          padding: 0px 10px 4px 10px;
          background-color: $event-list-page-content-item-bg;
        }

        &-td {
          background-color: transparent;
          font-size: 14px;
          line-height: 20px;
        }

        &-time {
          grid-column-start: 1;
          grid-column-end: 2;
          justify-content: flex-start;

          .wrapper {
            flex-direction: row;

            .label,
            .date {
              padding-left: 5px;
            }
          }
        }

        &-icons {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
          display: flex;
          justify-content: flex-end;
          padding: 0;
          grid-column-gap: 0;

          span {
            &:not(:last-child) span {
              padding-right: 15px;
            }
          }
        }

        &-eventName {
          padding-left: 0;
          grid-column-start: 1;
          grid-column-end: 3;
          margin-top: 0px;
          line-height: 15px;
        }

        &-result {
          padding: 0;
          margin-top: 0px;
          grid-column-gap: 6px;
          grid-template-rows: 24px 1fr;
          grid-column-start: 1;
          grid-column-end: 3;
          &-cell {
            span {
              width: calc((100% - 3px) / 2);
              padding: 0px 0 0px 0;

              &:first-child {
                margin-right: 3px;
              }
            }
          }

          &-error {
            padding: 0px 0 0px 0;
          }

          &-header {
            grid-column-start: 1;
            grid-column-end: 4;

            span {
              width: calc((100% - 12px) / 3);
              display: inline-flex;
              align-items: center;
              justify-content: center;
              color: $complementary-grey;
              line-height: 10px;

              &:not(:last-child) {
                margin-right: 6px;
              }
            }
          }
        }
      }
      &_collapsed{
        width: 100%;
        height: 28px;
        border-radius: 10px;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color:#00A826;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
        text-transform: uppercase;
      }
    }
  }

////////////////////////////////

.eventCard{
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #F6F8FC;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-bottom: 7px;
  padding: 11px 7px 7px 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :last-child {
    margin-bottom: 0;
  }
  &_live{
    margin-left: -7px;
  }
  &_eventSpecialChars{
    position: absolute;
    top: 43px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &-char{
      color: #000;
      font-family: Roboto;
      font-size: 14px;
      font-style: italic;
      font-weight: 900;
      right: 9px;
    }
  }
  &_futureEventTag{
    position: absolute;
    right: 13px;
    top: 0px;
    width: auto;
    height: auto;
    background-color: #FF7A40;
    border-radius: 0 0 5px 5px;
    display: flex;
    justify-content: center;
    color: #FFF;
    align-items: center;
    flex-direction: column;
    font-family: Roboto;
    padding: 4px 10px;

    &-day{
      font-size: 12px;
      font-style: normal;
      font-weight: 700;

      &::before{
        content: '';
        width: 0;
        height: 0;
        border-top: 6px solid #F2733C;
        border-left: 6px solid transparent;
        position: absolute;
        top: 0;
        left: -6px;
      }
      &::after{
        content: '';
        width: 0;
        height: 0;
        border-top: 6px solid #F2733C;
        border-right: 6px solid transparent;
        position: absolute;
        top: 0;
        right: -6px;
      }
      &.tomorrow{
        &::before{
        border-top: 6px solid #3474DF;
      }
      &::after{
        border-top: 6px solid #3474DF;
      }}
      &.later{
        &::before{
          border-top: 6px solid #474951;
        }
        &::after{
          border-top: 6px solid #474951;
        }
      }
    }
    &.tomorrow{
      background-color: #4285F4;
    }
    &.later{
      background-color: #686B74;
    }
    &-time{
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
    }

  }
  &_header{
    display: flex;
    justify-content: start;
    align-items: start;
    img{
      margin-right: 12px;
    }
    &-infoBox{
      display: flex;
      flex-direction: column;
      justify-content: start;
      height: 56px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.0015em;
      color: #000000;
      margin-bottom: 15px;
      width: 100%;
      gap: 5px;
      &-matchInfo{
        color: #FF7A40;
        background-color: #FFD5C2;
        width: max-content;
        padding: 2px;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 3px;
      }
      &.racing{
        height: unset;
      }
      &-runners{
        font-family: Roboto;
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
      }
    }
  }
  &_marketTitles{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_item{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33%;
      &-teamFlag{
        width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 6px;
    }
    &-teamName{
      font-weight: 500;
      color: #000000;
      font-size: 14px;
      &.draw {
        font-size: 12px;
      }
    }
  }
  }
  &_marketButtons{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.inactive{
      opacity: 0.4;
    }
    &_item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 33%;
      &-back{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        background: #72BBEF;
        width: 100%;
      }
      &-lay{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        background: #FAA9BA;
        width: 100%;
      }
    }
  }
}
.game-table__body-result-first-back{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

@keyframes liveDot {
  0%{
    scale: 1;
  }
  50%{
    scale: 1.1;
  }
  100%{
    scale: 1;
  }
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(2.5, 2.5);
    background-color: rgba(red, 0);
  }
}


////////////////////////////////

@media all and (max-width: 390px) {
  .eventCard{
    min-height: 140px;
    background: #F6F8FC;
    padding: 10px 6px 7px 6px;
    &_header{
      &-infoBox{
        line-height: 18px;
        height: 43px;
        &-matchInfo {
          font-size: 11px;
        }
      }
    }
    &_marketButtons{
      margin-top: 7px;
    }
  }
}
@include respond(Medium) {
  div.game {
    min-height: calc(100vh - 90px);

    &-table {
      padding: 15px;
    }
  }
}
@media (max-width: 1024px) {
  .eventCard{
    padding: unset;
  min-height: unset;
  flex-direction: unset;
    display: flex;
    align-items: center;
  &_eventSpecialChars{
    position: relative;
    top: unset;
    flex-direction: column;
    gap: 0;
    right: 7px;
    width: 5%;
    &-char{
      font-size: 11px;
    }
  }
  &_header{
    img{
      margin-right: 7px;
      width: 18px;
      height: 18px;
    }
    &-infoBox{
      height: unset;
      &-runners-phoneBox{
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
      }
    }
  }
  &_live{
    left: 15px;
    top: 36px;
    right: unset;
    &.racingLive{
      position: absolute;
      right: 5px;
      top: 5px;
      height: 15px;
      margin-left: 20px;
      left: unset;
    }
  }
  &_futureEventTag{
    position: relative;
    right: unset;
    top: unset;
    flex-direction: row;
    height: 18px;
    border-radius: 3px;
    white-space: nowrap;
    &-day{
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      margin-right: 3px;
      &::before{
        border: unset;
      }
      &::after{
        border: unset;
      }
    }
    &-time{
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      white-space: nowrap;
    }
  }
    &_mobileWrapper{
      width: 75%;
      padding: 0 0 0 13px;
    }
    &_marketButtons{
      width: 30%;
      flex-direction: column;
      align-items: flex-end;
      margin: 0;
      height: 100%;
      &_inactiveItem{
        opacity: 0.4;
      }
      &_item{
        width: 100%;
        height: calc(100% / 3);
        &-teamInfo{
          width: 40%;
          background-color: white;
          font-family: 'Roboto';
          height: calc(100% - 2px);
          font-size: 11px;
          font-style: normal;
          display: flex;
          font-weight: 500;
          color: black;
          justify-content: center;
          align-items: center;
          margin-right: 2px;
          line-height: normal;
        }
        &-back{
          min-height: 22.3px;
          height: 100%;
        }
        &-lay{
          min-height: 22.3px;
          height: 100%;
        }
      }
    }
  }
}