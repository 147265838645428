@import "../../../static/scss/media";
@import "../../../static/scss/variables";

.modalButtons {
  position: absolute;
  bottom: 130px;
  width: 100%;

  &.android {
    bottom: 180px;
  }

  .whatsApp {
    position: absolute;
    left: 10px;
    bottom: -50px;
    z-index: 3;
    width: 60px;
    height: 60px;
    cursor: pointer;

    &_img {
      position: relative;
      z-index: 2;
    }

    .circleIconPanel {
      width: 250px;
      height: 50px;
      border-radius: 25px;
      z-index: 1;
      position: absolute;
      left: 23px;
      bottom: 6px;
      opacity: 0;
      background-color: #00A826;
      color: white;
      padding: 8px 18px 10px 40px;
      line-height: 18px;
      overflow: hidden;
      font-size: 15px;
      white-space: nowrap;
      div {
        white-space: nowrap;
      }

      &.showMessage {
        opacity: 1;
        animation: showMessage 1s linear;
      }

      &.hideMessage {
        opacity: 0;
        animation: hideMessage 1s linear;
        width: 0;
      }
    }

    @keyframes showMessage {
      0%,
      25% {
        opacity: 0;
        width: 0;
      }
      100% {
        opacity: 1;
        width: 250px;
      }
    }
    @keyframes hideMessage {
      0% {
        opacity: 1;
        width: 250px;
      }
      80%,
      100% {
        opacity: 0;
        width: 0;
      }
    }
  }

  .betslipButtonDesktop {
    display: none;
    position: absolute;
    right: 10px;
    bottom: -55px;
    z-index: 3;
    cursor: pointer;

    &-content {
      width: 195px;
      height: 50px;
      background: #00A826;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;

      &-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
      }

      &-count {
        width: 25px;
        height: 20px;
        background: #000000;
        border-radius: 50px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
      }
    }
  }

  .messageButtonDesktop {
    display: block;
    position: absolute;
    right: 20px;
    bottom: -44px;
    z-index: 3;

    &-content {
      width: 50px;
      height: 50px;
      background: #23343E;
      border-radius: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  }

}

@media (min-width: 1024px) {
  .modalButtons {
    position: sticky;
    .whatsApp {
      position: absolute;
      bottom: -60px;
    }
    .betslipButtonDesktop {
      display: block;
      position: absolute;
      bottom: -60px;
      right: 90px;
      &-content {
      height: 56px;
    }
    }
    .messageButtonDesktop {
      display: block;
      position: absolute;
      right: 20px;
      bottom: -60px;
      z-index: 3;
      cursor: pointer;

      &-content {
        width: 56px;
        height: 56px;
        background: #23343E;
        border-radius: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
