@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


img {
  max-width: unset;
  height: auto;
}
sup {
  top: auto;
}
