@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

div.MuiSnackbar-anchorOriginBottomCenter {
  left: 0;
  right: 0;
  bottom: unset;
  top: 10px;
  transform: translateX(0);
  position: absolute;
}

header {
  width: 100%;
  background: $header-background;
  box-sizing: border-box;

  .header-container {
    height: $header-height;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px 0 30px;
  }

  .header__left-side {
    display: flex;
    align-items: center;

    .menu {
      cursor: pointer;
      display: flex;
      border-radius: 5px;
      width: 36px;
      height: 36px;
      align-items: center;
      justify-content: center;
      margin-right: 30px;

      img {
        width: 26px;
        height: 20px;
      }

      &.home {
        width: 24px;
        height: 17px;
        background-image: url(../../../static/images/header/back.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    .logo {
      margin-right: 14px;
      height: 40px;
      width: 152px;
      background-image: $url-logo-desktop;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      &-wrapper {
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .android {
      margin-right: 14px;
      height: 40px;
      width: 30px;
      background-image: url(../../../static/images/icons/android-svgrepo-com.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      &-wrapper {
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .page-name {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.02em;
      flex-shrink: 0;
      text-transform: capitalize;
    }
  }

  .divider {
    width: 2px;
    height: 36px;
    background: $header-input-bg;
    margin: 0 24px;
  }

  .header__right-side {
    display: flex;
    align-items: center;

    &-search-img {
      width: 22px;
      height: 33px;
      margin-right: 10px;
    }

    .search {
      &-bar {
        max-width: 230px;
        height: 36px;
        background: rgba(255, 255, 255, 0.16);
        border-radius: 5px;
        display: flex;
      }

      &-input {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;

        color: $header-text;

        &::placeholder {
          opacity: 0.7;
        }
      }
    }
    .custom-button-default {
      cursor: pointer;
      border-radius: 5px;
      font-weight: bold;
      font-size: 14px;
      font-weight: 700;
      padding: 8px 23px;
      min-height: 40px;
      padding: 0;
      width: 80px;
      height: 40px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-buttons {
      transition: all 0.3s;
      display: flex;
      &-button.sign-up {
        color: $header-button-text-secondary;
        background-color: $header-button-secondary;
        width: 98px;
      }
      &-button.deposit {
        color:  $header-button-secondary;
        background-color: $header-button-text-secondary;
        width: 80px;
      }
      &-button.user {
        color:  $header-button-text-secondary;
        background-color: $header-button-secondary;
        min-width: 103px;
        width: auto;
        padding: 0 10px;
      }
      &-rules-button {
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $header-input-bg;
        border-radius: 5px;
        img {
          width: 21px;
          height: 21px;
        }
      }
    }
    &-user {
      margin-left: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-wallet, &-logo {
        width: 31px;
        height: 31px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }

  }
}
@media (max-width: 1100px) {
  header {
    width: 100%;
    background: $header-background;
    box-sizing: border-box;

    .header-container {
      padding: 0 15px 0 20px;
    }
    .divider {
      margin: 0 15px;
    }
  }
}

@media (max-width: 1024.5px) {
  header {
    .header-container {
      height: $header-height-mobile;
      padding: 0 10px 0px 10px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      &__slose-search {
        position: absolute;
        right: 15px;
      }
      &.bottomHeader{
        background: #000000;
        position: fixed;
        bottom: 0;
        z-index: 2;
      }
    }

    .header__left-side {
      .menu {
        margin-right: 25px;
      }

      .logo {
        width: 31px;
        height: 31px;
        background-image: $url-logo;
      }

      .android {
        width: 25px;
        height: 30px;
        background-image: url(../../../static/images/icons/android-svgrepo-com.svg);
      }

      .divider {
        display: none;
      }
      &-page {
        color: $header-text;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
      }
    }

    .rules-button {
      display: none;
    }

    .header__right-side {
      .custom-button-default {
        font-size: 14px;
        font-weight: 700;
        min-height: 34px;
        width: 60px;
        height: 34px;
        margin-left: 10px;
      }
      &-buttons {
        display: flex;
        &-button.sign-up {
          color: $header-button-text-secondary;
          background-color: $header-button-secondary;
          width: 72px;
        }
        &-button.deposit {
          color:  $header-button-secondary;
          background-color: $header-button-text-secondary;
          width: 73px;
        }
        &-button.user {
          color:  $header-button-text-secondary;
          background-color: $header-button-secondary;
          min-width: 78px;
          width: auto;
          padding: 0 10px;
        }
      }
      &-user {
        margin-left: 16px;
         &-logo {
          width: 26px;
          height: 26px;
           margin-right: 5px;
           img {
             width: 100%;
             height: auto;
           }
        }
      }
    }
  }

div.MuiSnackbar-anchorOriginBottomCenter {
top: 60px;
}
}
@media (max-width: 430px) {
  header {
    .header__left-side {
      .menu {
        margin-right: 20px;
      }
    }
    .header__right-side-search-img {
      margin-right: 5px;
    }
    .header__left-side-page {
      max-width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 22px;
    }
    .header__right-side {
      .custom-button-default {
        margin-left: 10px;
      }
    }
    .header__right-side-buttons-button {
      transition: all 0.3s;
      &.deposit{
        width: unset;
        padding: 0 10px;
      }
      &.user{
        padding: 0 10px;
      }
    }
  }
}
@media (max-width: 395px) {
  header {
    .header-container {
      height: $header-height-mobile;
      padding: 0 10px;
    }

    .header__left-side {
      .menu {
        margin-right: 10px;
      }
    }
    .header__right-side-search-img {
      margin-right: 5px;
    }
    .header__left-side-page {
      font-size: 22px;
    }
    .header__right-side {
      .custom-button-default {
        margin-left: 5px;
      }
    }
    .header__right-side-buttons-button {
      &.deposit{
        width: unset;
        padding: 0 7px;
      }
      &.user{
        padding: 0 7px;
      }
    }
  }
}

@media (max-width: 370px) {
  header {
    .header__left-side {
      .menu {
        margin-right: 10px;
      }
    }
   .header__right-side-search-img {
     margin-right: unset;
    }
    .header__left-side-page {
      max-width: auto;
      font-size: 20px;
      @media (max-width: 350px) {
        max-width: 96px;
      }
    }
    .header__right-side {
      .custom-button-default {
        margin-left: 5px;
      }
    }
    .header__right-side-buttons-button {
      &.deposit{
        width: unset;
        padding: 0 5px;
      }
      &.user{
        padding: 0 5px;
      }
    }
  }
}
