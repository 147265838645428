@import '../../../../static/scss/variables.scss';

.searchEvent-container {
  width: 160px;
  height: 36px;
  position: relative;

  &__input {
    width: 100%;
    height: 100%;
    background: $search-event-bg;
    border-radius: 5px;
    padding: 6px 6px 6px 42px;
    border: none;
    outline: none;
    color: $search-color-secondary;
    font-size: 14px;
    font-weight: 600;

    &::placeholder {
      color: $search-color;
      opacity: 1;
    }

    &:focus {
      background: $common-white;
    }
  }

  &-img {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 15px;
  }

  &__polygon {
    position: absolute;
    top: 35px;
    right: 60px;
  }

  &__eventBox {
    position: absolute;
    width: 350px;
    max-height: 80vh;
    overflow: auto;
    background: $search-result-bg;
    z-index: 13;
    top: 47px;
    right: 0;
    border-radius: 0 0 20px 20px;
    padding: 23px;
  }
}

.event-container {
  padding: 8px 13px;
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  background-color: $search-result-item-bg;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &__title {
    &-event {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $search-result-item-color;
    }

    &-competition {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 12px;
      color: $search-result-item-color-secondary;
    }
  }
  &__leftside{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__rightside {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.noEventFound {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .searchEvent-container {
    width: 100%;
    height: 36px;
    position: relative;
    input:not([value=""]) {
      box-shadow: unset !important;
    }
    &__input {
      width: 100%;
      height: 100%;
      background: transparent;
      border-radius: 5px;
      padding: 6px 39px;
      border: none;
      outline: none;
      color: $search-color-mobile;
      font-size: 14px;
      font-weight: 400;

      &::placeholder {
        color: transparent;
        opacity: unset;
      }

      &:focus {
        background: unset;
      }
    }

    &-img {
      display: none;
    }

    &__polygon {
      position: absolute;
      top: 35px;
      right: 60px;
    }

    &__eventBox {
      position: absolute;
      width: 100%;
      height: auto;
      background: $search-result-bg;
      z-index: 13;
      top: 47px;
      right: 0;
      border-radius: 0 0 20px 20px;
      padding: 23px;
    }
  }

  .event-container {
    padding: 8px 13px;
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
    background-color: $search-result-item-bg;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &__title {
      &-event {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $search-result-item-color;
      }

      &-competition {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 12px;
        color: $search-result-item-color-secondary;
      }
    }

    &__rightside {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 13px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .noEventFound {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #000000;
    display: flex;
    align-items: center;
  }
}
