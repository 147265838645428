@import '../../static/scss/media.scss';
@import '../../static/scss/variables.scss';

.noData {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: auto;
    height: auto;
  }

  &__text {
    font-family: Source Sans Pro;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-top: 30px;
  }
}

@include respond(Medium) {
  .noData {
    &__img {
      max-width: 220px;
      width: 100%;
    }

    &__text {
      margin-top: 15px;
      padding: 0 25px;
      text-align: center;
    }
  }
}
