@import '../../static/scss/variables.scss';

.loginPop-up {
  .loginPop-up_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0, 1) 60%, rgba(0,0,0,0.5) 100% ) !important;
    z-index: 555;

    .loginPop-up {
      width: 100%;
      height: auto;
      max-width: 550px;
      background: linear-gradient(to bottom, #00A826 40%, #f2f2f2 0%);
      position: absolute;
      animation: openPopUp .6s ease-in-out forwards;
      border-radius: 30px 30px 0 0;
      padding: 15px 15px 26px;
      &.closed{
        animation: closePopUp 0.6s ease-in-out forwards;
      }
    }

    @keyframes openPopUp {
      0% {
        bottom: -100%
      }
      100% {
        bottom: 0%
      }
    }

    @keyframes closePopUp {
      0% {
        bottom: 0%
      }
      100% {
        bottom: -100%
      }
    }

    .loginPop-up-content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

.loginPop-up-form__content-wrapp {
  position: relative;
}

  .loginPop-up-closeIcon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  .loginPop-up-backIcon {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(180deg);
    background: #000;
    border-radius: 10px;
    padding: 3px 8px;
  }


  .loginPop-up-form__content {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 29px 33px;
    &-text{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #000000;
    }
    .formik-field {
      height: 40px;
      width: 100%;
      margin-bottom: 25px;
      max-width: unset;

      input {
        height: 40px;
        width: 100%;
        padding: 0 12% 0 3% !important;
        padding-left: 10px;
      }
    }
  }

  .loginPop-up-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    .animatedText{
      animation: animatedText .5s ease;
    }
    .animatedText1{
      animation: animatedText1 .5s ease;
    }
  }

  .loginPop-up-form__log-in-button-icon {
    position: absolute;
    top: 8px;
    right: 10px;
  }

  .loginPop-up-form__text {
    margin-top: 15px;
    margin-bottom: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
  }

  .loginPop-up-form__description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 18px;
  }

  .loginPop-up-form__privacy-policy {
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: $login-page-input-bg;

    .MuiCheckbox-colorSecondary.Mui-disabled {
      color: $black-text;
      padding-left: 0;
    }

    p {
      color: $black-text;
    }

    .loginPop-up-form__privacy-policy--allocated {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.001em;
      color: #00A826;
      cursor: pointer;
    }
  }

  .formik-field__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 10px;
    top: 7px;
  }

  .loginPop-up-form__alert {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 15px;
    background-color: $login-page-error;
    top: 0;
    margin-top: 40px;
    width: 95%;
    max-width: 490px;
    z-index: 1000000000;

    & p {
      color: $login-page-content-text;
    }
  }

  .loginPop-up-form__log-in-button {
    margin-top: 20px;
    height: 40px;
    background: #000000;
    white-space: nowrap;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-transform: uppercase;
    color: #F8F9FB;
  }
  .privacyText{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.001em;
    color: #000000;
  }

  fieldset legend {
    display: none;
  }

  .MuiOutlinedInput-root {
    margin: 0;
  }

  .MuiFormLabel-root {
    color: #15161B;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $black-text;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 12px) scale(1);
    pointer-events: none;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $black-text;
    border-radius: 5px;
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: $login-page-error;
  }

  input {
    box-shadow: inset 0 0 0 50px $common-white;
  }

  .PrivateNotchedOutline-legendNotched-17 {
    display: none;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }
}

@media (min-width: 810px) {
  .loginPop-up {
    .loginPop-up_wrapper {
      background: rgba(0, 0, 0, 0.3) !important;
      .loginPop-up {
        width: 95%;
        max-width: 490px;
        background: linear-gradient(to bottom, #00A826 40%, #f2f2f2 0%) !important;
        border-radius: 30px;
        padding: 15px 15px 0px;
        overflow: auto;
        animation: none;
        &.closed{
          animation: none;
        }
      }
    }

    .loginPop-up .loginPop-up-form__content-text {
      margin-bottom: 10px;
    }

    .loginPop-up-closeIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;

      img {
        height: 100%;
      }
    }

    .loginPop-up-form__content {
      .formik-field {
        height: 57px;
        width: 100%;
        margin-bottom: 36px;
        max-width: unset;
        margin-top: unset;

        input {
          height: 57px;
          width: 100%;
        }
      }
    }

    .loginPop-up-form__log-in-button {
      height: 57px;
      cursor: pointer;
    }

    .loginPop-up-form__log-in-button-icon {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(20px, 20px) scale(1);
      pointer-events: none;
    }

    .formik-field__icon {
      position: absolute;
      width: 36px;
      height: 27px;
      right: 10px;
      top: 15px;
    }

    .loginPop-up-form__privacy-policy {
      display: flex;
      align-items: flex-start;
      padding-right: 15px;
      margin-top: 18px;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 25px;
      letter-spacing: 0.04em;
      color: $login-page-input-bg;

      .MuiCheckbox-colorSecondary.Mui-disabled {
        color: $black-text;
      }

      p {
        color: $login-page-privacy-policy;
      }

      .loginPop-up-form__privacy-policy--allocated {
        color: $login-page-content-active;
      }
    }

    .loginPop-up-form__alert {
      display: flex;
      position: absolute;
      width: 100%;
      padding: 15px;
      background-color: $login-page-error;
      top: 0;
      margin-top: 40px;
      width: 95%;
      max-width: 490px;
      z-index: 1000000000;

      & p {
        color: $login-page-content-text;
      }
    }

    .MuiFormLabel-root {
      color: #15161B;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $black-text
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: $login-page-error;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
    }

    input {
      box-shadow: inset 0 0 0 50px $common-white;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      display: none;
    }

    .MuiFormLabel-root.Mui-focused {
      color: $login-page-content-active;
    }
  }
}

.click-loginn {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000;
  margin-top: 15px;
  .click-loginn-span {
    margin-left: 3px;
    color: #00A826;
    cursor: pointer;
    text-decoration: underline;
  }
}

.formik-field__icon.username {
      cursor: default;
  }

  .loginPop-up-form__log-in-button.arrow::after {
    content: "";
    position: absolute;
    display: block;
    right: 110px;
    width: 15px;
    height: 14px;
    background-image: url(./arrowIcon.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .loginPop-up-form__log-in-button.loading::after {
    content: "";
    position: absolute;
    display: block;
    right: 100px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    animation: loadingSpinner 1s linear infinite;
  }


@media screen and (max-width: 800px) {
  .loginPop-up .loginPop-up-form__text {
    margin-top: 20px;
  }
}

.loginPop-up-form__log-in-button.arrow::after {
  right: 140px !important;
}

.loginPop-up-form__log-in-button.loading::after {
  right: 130px !important;
}

@media screen and (max-width: 768px) {
  .loginPop-up-form__log-in-button.arrow::after {
    right: 170px !important;
  }

  .loginPop-up-form__log-in-button.loading::after {
    right: 160px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  .loginPop-up .loginPop-up_wrapper .loginPop-up {
    //height: max-content;
    overflow: auto;
  }
}

@media screen and (max-width: 389px) {
  .loginPop-up .loginPop-up_wrapper .loginPop-up {
    //height: max-content;
    overflow: auto;
  }
}

@media screen and (max-width: 525px) {
  .loginPop-up-form__log-in-button.arrow::after {
    right: 110px !important;
  }

  .loginPop-up-form__log-in-button.loading::after {
    right: 100px !important;
  }
}

@media screen and (max-width: 400px) {
  .loginPop-up-form__log-in-button.arrow::after {
    right: 90px !important;
  }

  .loginPop-up-form__log-in-button.loading::after {
    right: 80px !important;
  }
}

@media screen and (max-width: 320px) {
  .loginPop-up-form__log-in-button.arrow::after {
    right: 60px !important;
  }

  .loginPop-up-form__log-in-button.loading::after {
    right: 50px !important;
  }
}

@media screen and (max-width: 150px) {
  .loginPop-up .loginPop-up_wrapper .loginPop-up {
    overflow: auto;
  }
}

@media screen and (min-width: 810px) {
  .loginPop-up-logo {
    background-image: $url-logo-login;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    img {
      height: 100%;
    }
  }
}

.loginByWhatsapp{
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items:center;
  background: #00A826;
  color: #fff;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-transform: uppercase;
  color: #F8F9FB;
  position: relative;
  &-img{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 8px;
    top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .loginByWhatsapp{
    height: 57px;
    &-img{
      right: 50px;
      top: 9px;
    }
  }
}

.loginPop-upWith{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-container{
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
    &-row{
      width: 100%;
      height: 70px;
      background: #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      .iconTitle{
        display: flex;
        align-items: center;
        .WAIcon{
          width: 45px;
          height: 45px;
        }
        .title{
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.0015em;
          text-align: left;
          color: #000;
          margin-left: 20px;
        }
      }
    }
  }
  &-redirect{
    margin: 30px 0 20px 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.0015em;
    text-align: center;
    span{
      color: #00A826;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@keyframes animatedText {
  0% {
    opacity: 0.2;
    margin-left: -50px;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes animatedText1 {
  0% {
    opacity: 0.2;
    margin-right: -50px;
  }
  100% {
    opacity: 1;
    margin-right: 0px;
  }
}
