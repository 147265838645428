@import "../../../static/scss/media";
@import "../../../static/scss/variables";

.footer {
  width: 100%;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  background: $footer-bg;
  border-radius: 20px 20px 0px 0px;
  margin-top: 35px;
  padding: 30px 20px 130px 20px;
  color: $footer-color;
  .footer-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
  img {
    width: 100%;
    height: auto;
  }
  a {
    color: unset;
    text-decoration: unset;
    cursor: pointer;
    background-color: unset;
    font-family: unset;
  }
  .footer__social {
    width: 100%;
    &-download {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-title {
        font-weight: 700;
        font-size: 18px;
        display: flex;
        flex-direction: column;
      }
      &-button {
        width: 160px;
        height: 52px;
        cursor: pointer;
      }
    }
    &-row {
      background: $footer-button-color;
      border-radius: 10px;
      height: 55px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      &-whatsapp {
        display: flex;
        align-items: center;
        &-img {
          width: 31px;
          height: 31px;
          margin-right: 10px;
        }
        &-title {
          font-weight: 700;
          font-size: 14px;
        }
      }
      &-socials {
        display: flex;
        &-item {
          width: 25px;
          height: 25px;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 15px;
          }
          &:hover {
            transform: scale(1.1);
          }
          &.whatsApp-phone {
            img {
              width: 25px;
              height: auto;
            }
          }
          &-appBox{
            width: 100%;
            height: 100%;
            background-color: #2C2D34;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-radius: 10px;
            &-android{
              background-image: url('../../../static/images/footer/androidAnimation.gif');
              width: 66px;
              height: 62px;
              background-size: contain;
              background-repeat: no-repeat;
            }
            &-textRow{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              &-title{
                font-size: 14px;
                font-family: 'Roboto';
                font-weight: normal;
              }
              &-subTitle{
                font-size: 12px;
                font-family: "Roboto";
                font-weight: 900;
              }
            }
          }
        }
      }
    }
    &-row.phones {
      font-weight: 700;
      font-size: 14px;
      flex-wrap: wrap;
      height: unset;
      gap: 10px;
      .phones-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &-header {
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
        }
      }
      img {
        width: 25px;
        height: auto;
        margin-right: 13px;

      }
      .footer__social-row-socials-phones-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 3px;
      }
    }
  }
  .footer__info {
    margin-top: 18px;
    p {
      padding: 0;
      margin: 0;
      opacity: 0.7;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: $footer-color;
      line-height: 21px;
    }
  }
  .footer__paynents {
    width: 100%;
    display: flex;
    margin: 35px 0;
    justify-content: center;
    &-item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  .footer__term {
    padding: 30px 0 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
    &-rules {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
      opacity: 0.7;
      &>span {
        cursor: pointer;
      }
    }
    &-steps {
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      opacity: 0.7;
      letter-spacing: 0.0015em;
    }
  }
}

@media (min-width: 1024px) {
  .footer {
    background: $footer-bg;
    border-radius: unset;
    padding: 65px 20px 85px 20px !important;
    color: $footer-color;
    .footer-container {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
    }
    .footer-social-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .footer__social {
      width: 415px;
      &-download {
        &-title {
          font-weight: 700;
          font-size: 20px;
          text-transform: uppercase;
        }
        &-button {
          width: 182px;
          height: 59px;
        }
      }
      &-row {
        margin-top: 15px;
      }
      &-row.phones {
        img {
          width: 25px;
          height: auto;
          margin-right: 15px;
        }
        .footer__social-row-socials-phones-wrapper {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .footer__info {
      width: calc(100% - 600px);
      margin-top: unset;
      p {
        letter-spacing: 0.0015em;
        text-align: left;
      }
      .footer__info-logo {
        height: 52px;
        width: 200px;
        background-image: $url-logo-desktop;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        margin-bottom: 23px;
      }
    }
    .footer__paynents {
      width: 100%;
      height: 72px;
      background-color: $footer-payments-bg;
      margin: 30px 0 0 0;
      align-items: center;
      &-item {
        transform: scale(1.2);
        &:not(:last-child) {
          margin-right: 80px;
        }
      }
    }
    .footer__term {
      padding: 30px 0 0 0;
      border-top: unset;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-rules {
        margin-bottom: unset;
      }
    }
  }
}
