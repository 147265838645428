@import "../../../static/scss/media.scss";
@import "../../../static/scss/variables.scss";

@include respond(Medium) {
  aside.side-menu {
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
    animation: showup 0.3s linear;

    @keyframes showup {
      from {
        opacity: 0;
        transform: translateX(-100%);
      }

      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    div.wrapper {
      padding-bottom: 30px;
      position: relative;
      max-width: 90%;
      width: 100%;
      background: $leftside-menu-content-bg;
      overflow: hidden;
    }

    div.sections {
      height: 100%;
      overflow-y: scroll;
      padding-bottom: 150px;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: #d9dde4;
      }
    }

    div.top {
      display: flex;
      background: $leftside-menu-header-bg;
      padding: unset;
      justify-content: space-between;
      .logo {
            margin-right: 14px;
            height: 35px;
            width: 150px;
            // position: absolute;
            background-image: $url-logo-desktop;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            margin-left: 11px;
      }
      .close {
        background: $leftside-menu-header-bg;
      }
    }

    div.search-bar {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      height: 52px;
      background: transparent;
    }
  }
}

@media all and (max-width: 375px) {
  aside.side-menu {
    div.wrapper {
      max-width: 85%;
    }
  }
}

@media all and (max-width: 1024px) {
  .side-menu .section-wrapper {
    padding: unset !important;
}
}