.bonus-pop-up {
  background: white;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  overflow: auto;
  .bonus-pop-up__wrapper {
    background: linear-gradient(180deg, #DFE6FF 0%, rgba(223, 230, 255, 0.67) 68.75%, rgba(253, 253, 255, 0) 100%);
    width: 100%;
    height: 100%;
  }
  &.mobile {
    top: 60px;
  }
  &.mobile.iframe {
    top: 0;
    height: calc(100% - 60px);
  }
}
.bonus-pop-up.sidebar-pop-up {
  background: rgba(0, 0, 0, 0.3);
  .bonus-pop-up__wrapper {
    background: unset;
    position: relative;
    display: flex;
    justify-content: end;
    transition: all 0.5s;
    .sideBar-desktop, .sideBar-mobile {
      animation: openSidebar 0.2s ease-in-out forwards;
    }
  }
}
.bonus-pop-up.close-sidebar {
  .bonus-pop-up__wrapper {
    .sideBar-desktop, .sideBar-mobile  {
      animation: closeSidebar 0.2s ease-in-out forwards;
    }
  }
}
.bonus-pop-up.with-details {
  overflow: unset;
}
@keyframes openSidebar {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes closeSidebar {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
