@import "../../../../static/scss/media.scss";
@import "../../../../static/scss/variables.scss";

.banners {
  height: auto;
  width: 100%;
  overflow: hidden;

  .slider-container {
    width: 100vw;
    height: calc(100vw / 4.45);
  }
  .slider_banner {
    &__item {
      height: calc(100vw / 4.45);
      width: 100vw;

      &_img {
        width: 100vw;
      }
    }
  }
  .slider-control-centerleft, .slider-control-centerright{
    height: 100%;
    margin-right: 8px;
  }
  &_arrowControl{
    height: 100%;
    width: 40px;
    opacity: 0.5;
    &-arrow{
      content: url('../../../../static/images/sidemenu/arrow.svg');
      transform: rotate(-90deg);
      height: 15px;
      &.left{
        transform: rotate(90deg);
      }

    }
    &:hover{
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.537);
    }

  }
  .slider-control-bottomcenter{
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .banners .slider-container {
    width: 100vw;
    height: calc(100vw / 2.02);
    .slider-frame {
      .slider-list {
        height: calc(100vw / 2.02);
      }
    }
  }
  .banners {
    height: auto;
    width: calc(100% - 20px);
    margin: 0 auto;
    overflow-y: hidden;
    border-radius: 5px;
    .slider_banner {
      &__item {
        height: calc(100vw / 2.02);
        width: 100vw;
      }
    }
    .slider_banner__item_img {
      width: calc(100vw - 20px);
      border-radius: 5px;
    }
  }
  .banners::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
