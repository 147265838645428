@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

@media (max-width: 1024px) {
  .user-time-settings {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: $user-settings-color;
    width: 100%;
    height: 100%;
    padding: unset !important;
    transition: all 0.3s;
    .user-time-settings__content {
      height: 270px;
      width: 100%;
      background-color: $user-settings-header-bg;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .user-time-settings__content-img {
      width: 26px;
      height: 33px;
      margin-top: 43px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .user-time-settings__content-date {
      font-weight: 500;
      font-size: 18px;
      margin-top: 20px;
      color: $user-settings-color-mobile;
    }
    .user-time-settings__content-time {
      font-weight: 500;
      font-size: 13px;
      margin-top: 12px;
      color: $user-settings-color-mobile;
    }
    .user-time-settings__customize {
      background-color: $user-settings-bg-secondary;
      width: calc(100% - 46px);
      padding: 18px 20px 25px 20px;
      border: 1px solid $user-settings-bg;
      box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.03);
      border-radius: 10px;
      margin-top: 41px;
    }
    .user-time-settings__customize-title {
      color: $user-settings-color-secondary;
      font-weight: 400;
      font-size: 12px;
    }
    .user-time-settings__customize-dropdown {
      margin: 10px 0;
      height: 42px;
      border: 1px solid $user-settings-color;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      cursor: pointer;
      position: relative;
      font-weight: 600;
      background-color: $user-settings-bg-secondary;
      ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }

    .user-time-settings__content-button {
      background-color: $change-password-button-bg;
      color: $change-password-button-color;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      width: 100%;
      line-height: 0;
      height: 48px;
      border-radius: 5px;
      cursor: pointer;
      img {
        width: 15px;
        height: auto;
        margin-left: 15px;
      }
      //&.disabled {
      //  background-color: $change-password-button-bg;
      //}
      }
    .MuiSnackbar-anchorOriginTopCenter {
      transform: translate(0%, 100px);
    }
  }
  @keyframes openDetailsDesk {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes closeDetailsDesk {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  .user-time-settings__customize-dropdown-items-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
    ul, li {
      list-style: none;
      margin: unset;
      padding: unset;
    }
    .user-time-settings__customize-dropdown-items {
      width: 100%;
      position: absolute;
      animation: openPopUp 0.6s ease-in-out forwards;
      left: 0;
      width: 100%;
      background-color: #FFFFFF;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
      border-radius: 20px 20px 0px 0px;
      padding: 35px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-family: 'Roboto';
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      li {
        height: 42px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $user-settings-bg;
        border-radius: 5px;
        padding: 0 10px;
        margin: 2px 0;
        transition: all 0.3s;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
        &:hover {
          background-color: $user-settings-button-hover;
        }
        &.chosen {
          background-color: $user-settings-header-bg;
          color: $user-settings-button-color;
        }
      }
      &.hide-dropdown {
        animation: closePopUp 0.6s ease-in-out forwards;
      }
    }
  }
}
