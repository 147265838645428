@import '../../../static/scss/variables';

.notificationPopUp__popUpMobile{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3;
}

.notificationPopUp{
  width: 100%;
  height: auto;
  position: absolute;
  animation: boxAppend 0.6s ease-in-out;
  z-index: 2;
  bottom: 0%;
  top: unset;
  animation: unset;
  transition: bottom .5s;
  background: #D7D7D7;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  overflow: hidden;
  &.closed{
    bottom: -100%;
  }
  &_header{
    padding: 20px;
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: white;
    position: relative;
    background-color: $notification-header-bg;
    height: 110px;
    &-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        cursor: pointer;
      }
    }
  }
  &_content{
    padding: 20px;
    width: 100%;
    height: auto;
    color: black;
    position: relative;
    top: -72px;
    &_item{
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 16px 20px;
    }
  }
}

@media (min-width: 1024px) {
  .notificationPopUp__popUpMobile{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
  }

  .notificationPopUp{
    width: 430px;
    height: 560px;
    position: absolute;
    animation: boxAppend 0.6s ease-in-out;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: unset;
    transition: bottom .5s;
    background: #D6D6D6;
    box-shadow: 0px 0px 124px rgba(0, 0, 0, 0.18);
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    overflow: hidden;
    &.closed{
      bottom: -100%;
    }
    &_header{
      padding: 20px;
      width: 100%;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: white;
      position: relative;
      background-color: $notification-header-bg;
      height: 116px;
      &-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &_content{
      padding: 20px;
      width: 100%;
      height: auto;
      color: black;
      position: relative;
      top: -72px;
      &_item{
        background: #FFFFFF;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 16px 20px;
      }
    }
  }
}
