@import "../../../../static/scss/media.scss";
@import "../../../../static/scss/variables.scss";

@include respond(Medium) {
  aside.side-account {
    -webkit-animation: showright 0.4s linear;
    animation: showright 0.4s linear;

    @keyframes showright {
      from {
        -webkit-opacity: 0;
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
      }
      to {
        -webkit-opacity: 1;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
    }
    div.actions {
      animation: none;
    }
  }
}
