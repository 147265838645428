.openBetsContent{
    height: 502px;
    animation: openBets .5s ease;
}
.openBets-options{
    .openBetsShow{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.001em;
        color: #000000;
        display: inline-block;
        margin-right: 5px;
    }
    .openBetsOptionsRow{
        display: flex;
        margin: 11px 0 10px 0;
    }
    .openBets-checkedOption{
        height: 28px;
        background: #EEEEEE;
        border-radius: 50px;
        padding: 4px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 7px;
        cursor: pointer;
        span{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: -0.001em;
            color: #000000;
            display: inline-block;
            margin: 0 6px;
        }
    }
    .matched{
        background: #fff;
    }
}

.openBets-unmachedContainer{
    background: #EAF6FF;
    border-radius: 10px;
    padding: 15px 14px 19px 17px;
    margin-bottom: 12px;
    &-row{
        display: flex;
        justify-content: space-between;
        .row-title{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }
    }
    &-noBets{
        background: #FFFFFF;
        border-radius: 5px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
        margin-top: 11px;
    }
    &-bets{
        background: #FFFFFF;
        border-radius: 5px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #000000;
    }
    .matchedType{
        margin-top: 11px;
        span{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.001em;
            color: #000000;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

.openBets-placedBets{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px;
    margin-top: 11px;
    &-title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #000000;
    }
    &-descr{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
    }    
    &-betInfo{
        display: flex;
        justify-content: space-between;
        div{
            color: #aeabab;
            font-size: 11px;
        }
    }
    &-button{
        background: #F0F0F0;
        border-radius: 5px;
        width: 100%;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        margin-top: 5px;
        cursor: pointer;
    }
}
.layOpenBets{
    border-left: 3px solid #FAA9BA;
}
.backOpenBets{
    border-left: 3px solid #72BBEF;
}

@keyframes openBets {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.hideUnmachedContainer{
    height: 45px;
    overflow: hidden;
}

.hideMachedContainer{
    height: 45px;
    overflow: hidden;
}
.hideOpenBetsIcon{
    height: 18px;
    width: 18px;
    background: #000;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}


