@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

.exchange-games-page {
  width: 100%;

  .exchange-games-page__menu {
    display: flex;
    margin: 20px 0;
    overflow-x: scroll;

    .exchange-games-page__menu-item {
      height: 40px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border: 1px solid #9DA2AF;
      border-radius: 70px;
      margin-right: 10px;
      cursor: pointer;
      animation: start 0.3s linear forwards;
      transition: all 0.3s;

      span {
        font-family: 'Lato';
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        white-space: nowrap;
        line-height: 0;
      }

      &.active {
        transition: all 0.3s;
        background: #00A826;
        border: 1px solid rgba(0, 0, 0, 0.1);

        span {
          color: #FFFFFF;
        }
      }

      &:first-child {
        margin-left: 15px;
      }

      &:active {
        transform: scale(0.95);
      }
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  @keyframes start {
    0% {
      padding: 0 0;
      opacity: 0.5;
    }
    100% {
      padding: 0 20px;
      opacity: 1;
    }
  }
  .exchange-games-page__info {
    display: none;
  }
  .exchange-games-page__games {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: start;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
    height: auto;
    column-gap: 20px;

    &-item {
      width: calc((100vw - 40px) / 2);
      margin-bottom: 5px;
      cursor: pointer;
      background: #555555;
      border-radius: 10px;
    }

    &-item-img-body {
      width: 100%;

      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        animation: show 0.3s ease-out forwards;
      }
    }
    &.no-data {
      transition: height 2s;
      height: 50vh;
    }
  }
  @keyframes show {
    0% {
      opacity: 0.5;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

@media (min-width: 501px) {
  .exchange-games-page {
    .exchange-games-page__games {
      &-item {
        width: calc((95vw - 50px) / 3);
      }
    }
  }
}
@media (min-width: 1024px) {
  .exchange-games-page {
    width: 100%;
    .exchange-games-page__menu {
      display: flex;
      margin: 35px 0 45px 0;
      overflow-x: scroll;

      .exchange-games-page__menu-item {
        height: 44px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        border: 1px solid #9DA2AF;
        border-radius: 70px;
        margin-right: 10px;
        cursor: pointer;
        animation: start 0.3s linear forwards;
        transition: all 0.3s;

        span {
          font-family: 'Lato';
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          white-space: nowrap;
          line-height: 0;
        }

        &.active {
          transition: all 0.3s;
          background: #00A826;
          border: 1px solid rgba(0, 0, 0, 0.1);

          span {
            color: #FFFFFF;
          }
        }

        &:first-child {
          margin-left: unset;
        }

        &:active {
          transform: scale(0.95);
        }
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .exchange-games-page__info {
      display: block;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 30px;
      color: #000000;
      margin-bottom: 35px;
      &-title, &-sub-title  {
        margin-bottom: 9px;
      }
      &-text {
        font-weight: 600;
        font-size: 14px;
      }
    }

    .exchange-games-page__games {
      padding: unset;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: start;
      position: relative;
      overflow: hidden;
      transition: all 0.3s;
      height: auto;
      column-gap: 25px;

      &-item {
        width: calc((100% - 100px) / 5);
        margin-bottom: 5px;
        &.small-row {
          width: calc((100% - 100px) / 3);
        }
      }

      &-item-img-body {
        width: 100%;

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          animation: show 0.3s ease-out forwards;
        }
      }
      &.no-data {
        height: 50vh;
      }
    }
  }
}