@import "./buttons.scss";
@import "./variables.scss";
html {
  color: $main-text-color;
}
p {
  color: $main-text-color;
  margin: 0;
}
@mixin bold-text {
  font-weight: 500;
  color: $main-text-color;
}

.bold-text {
  @include bold-text;
}

h2 {
  @include bold-text;
  font-size: 24px;
}

.validation-error {
  height: 10px;
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin-left: 5px;
  width: 100px;
  white-space: nowrap;
}

.global-desktop-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

html,
body {
  scrollbar-color: #a7a7a8 #d9dde4;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 10px;
  height: 4px;
  background-color: #d9dde4;
}
::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #a7a7a8;
  border-radius: 1em;
  box-shadow: inset -2px 1px 6px 0px #969696;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(75, 75, 75);
}

@media all and (max-width: 500px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #d9dde4;
  }
  ::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #a7a7a8;
    border-radius: 1em;
    box-shadow: inset -2px 1px 6px 0px #969696;
  }
}
