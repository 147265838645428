@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';

.bonus-details-pop-up-wrapper {
  width: 100%;
  height: 100%;
  z-index: 16;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.bonus-details-pop-up {
  width: 430px;
  height: 448px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 25px 33px 33px 33px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  animation: openDetailsDesk 0.25s linear forwards;
  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__title {
    font-size: 20px;
    text-align: center;
  }
  ul.bonus-details-pop-up__balance, ul.bonus-details-pop-up__bonus {
    margin: 25px 0;
    li {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      padding: 0 13px;
    }
    li:nth-child(odd) {
      background-color: #F6F6F6;
    }
  }
  ul.bonus-details-pop-up__bonus {
    margin: 10px 0 15px 0;
  }

  $width: 40px;
  $height: 22px;
  &__toggle {
    cursor: pointer;
    .slider {
      display: inline-block;
      width: $width;
      height: $height;
      background-color: $bonus-page-toggle-bg-inactive-details;
      border-radius: calc(#{$height} / 2);
      position: relative;
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      margin-top: 2px;
    }
    .slider::after {
      content: "";
      display: inline-block;
      width: #{$height};
      height: #{$height};
      background-color: $bonus-page-toggle-bg-details;
      background-position: center;
      border-radius: calc(#{$height} / 2);
      position: absolute;
      top: 0;
      transform: translateX(0);
      box-shadow: 10px 0 calc(10px * 4) rgba(0, 0, 0, 0.1);
      transition: 0.3s all ease-in-out;
    }
    .slider.active::after {
      content: "";
      display: inline-block;
      width: #{$height};
      height: #{$height};
      background-color: $bonus-page-toggle-bg-details;
      background-image: url("../../../../static/images/bonus/loading.svg");
      background-size: 35px;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: calc(#{$height} / 2);
      position: absolute;
      top: 0;
      transform: translateX(0);
      box-shadow: 10px 0 calc(10px * 4) rgba(0, 0, 0, 0.1);
      transition: 0.3s all ease-in-out;
    }

    .switch input[type="checkbox"]:checked + .slider {
      background-color: $bonus-page-toggle-bg-active;
    }

    .switch input[type="checkbox"]:checked + .slider::after {
      transform: translateX(calc(#{$width} - #{$height}));
      box-shadow: calc(10px * -1) 0 calc(10px * 4) rgba(0, 0, 0, 0.1);
      background-color: $bonus-page-toggle-bg;
    }

    .switch input[type="checkbox"] {
      display: none;
    }

    .switch input[type="checkbox"]:active + .slider::after {
      width: $width;
    }

    .switch input[type="checkbox"]:checked:active + .slider::after {
      transform: translateX(0.5px);
      background-color: $bonus-page-toggle-bg;
    }
  }

  &-toggle.not-clickable {
    .slider {
      cursor: none;
    }
    pointer-events: none;
  }
  &__bonus-title {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
  }
  &__terms-and-conds {
    font-weight: 600;
    font-size: 14px;
    color: $bonus-page-details-color-secondary;
    text-align: center;
    text-decoration-line: underline;
    cursor: pointer;
  }
  &__bonus-date {
    font-weight: 500;
    font-size: 14px;
    span {
      color: $bonus-page-details-color-secondary;
    }
  }

  &.createMarkup {
    width: 100%;
    height: unset;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .createMarkup-content {
      height: 200px;
      max-height: 40vh;
      overflow: auto;
      padding: 10px 10px 0 10px;
    }
    .createMarkup-button {
      padding: 5px 50px;
    }
  }
  &.close {
    animation: closeDetailsDesk 0.25s linear forwards;
  }
}

@keyframes openDetailsDesk {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes closeDetailsDesk {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@media screen and (max-width: 851px) {
  .bonus-details-pop-up {
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: all 0.5s;
    animation: openDetails 0.25s linear forwards;
    &.createMarkup {
      width: 100%;
      height: unset;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .createMarkup-content {
        height: 200px;
        max-height: 40vh;
        overflow: auto;
        padding: 10px 10px 0 10px;
      }
      .createMarkup-button {
        padding: 5px 50px;
      }
    }
    &.close {
      animation: closeDetails 0.25s linear forwards;
    }
  }
  @keyframes openDetails {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes closeDetails {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
}
