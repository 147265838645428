@import "./phone.scss";
@import '../../../static/scss/variables.scss';

.slot {
  overflow-y: auto;
  padding: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  background: $primary-background;

  &__header {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: $common-white;
    background: $primary-background;
    padding: 3px 20px;

    &:before {
      content: url(../../../static/images/slot-game.svg);
      padding-right: 15px;
    }
  }

  &__wraper {
    padding: 0 15px;
  }

  &__menu {
    list-style-type: none;
    padding: 0 20px;
    margin: 0;
    display: flex;
    background: $common-theme-color;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);

    &-item {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #BFC4D8;
      padding: 15px 0 13px 0;
      border-bottom: 2px solid transparent;
      text-transform: capitalize;

      &:hover {
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: 35px;
      }

      &.active {
        color: $common-white;
        border-color: $common-active-color;
      }
    }
  }

  &__body {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 40px;

    a {
      text-decoration: none;
    }

    &-cards {
      text-decoration: none;

      &-wrapp {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        min-height: 160px;
        max-height: 160px;
        width: 160px;
        cursor: pointer;
        background: #555555;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &:hover {
          .slot__body-cards-btn {
            position: absolute;
            opacity: 100;
            top: 50%;
            left: 50%;
            width: 98px;
            height: 46px;
            background: $common-theme-color;
            color: $common-white;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(-50%, -50%);
            transition: opacity 0.4s ease-out;
            transition-timing-function: ease-out;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
          }

          img {
            min-height: 210px;
            transition: min-height 0.5s;
          }

          &::before {
            background: rgba(0, 0, 0, 0.6);
          }
        }
      }

      &-btn {
        opacity: 0;
      }

      img {
        width: 100%;
        min-height: 200px;
        object-fit: cover;
      }

      &-name {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: $common-theme-color;
        margin-top: 15px;
        word-break: break-all;
      }

      &-second-name {
        position: absolute;
        opacity: 100;
        top: 30%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        color: #0074E3;
        margin-top: 15px;
        word-break: break-all;
        text-align: center;
        letter-spacing: 1px;
      }
    }
  }

  &__button {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: $common-theme-color;
    margin: 10px auto 0 auto;
    background: transparent;
    border: none;
    display: block;

    &:after {
      content: url(../../../static/images/Arrow.svg);
      padding-left: 7px;
    }

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      color: #BFC4D8;

      &:after {
        content: '';
        padding-left: 0;
      }
    }
  }

  &__deception-top {
    height: 80px;
    width: 100%;
  }

  &__fix-top {
    position: fixed;
    width: calc(100vw - 250px);
    z-index: 10;

    &::before {
      content: '';
      position: absolute;
      top: -15px;
      left: 0;
      width: 100%;
      background-color: black;
      background: $primary-background;
      height: 15px;
    }
  }

  &__bottom-load {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
  }
}

@media all and (max-width: 1800px) {
  
  .slot {
    &__body {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

@media all and (max-width: 1480px) {
  .slot {
    &__menu {
      overflow-x: auto;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        height: 1px;
      }

      &-item {
        flex-shrink: 0;
      }
    }
  }
}

@media all and (min-width: 1024px) and (max-width: 1300px) {
  .slot {
    padding-left: 15px;
    padding-right: 15px;

    &__body {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      grid-template-columns: repeat(4, 1fr);

      &-cards {
        &-wrapp {
          max-height: 150px;
        }

        img {
          min-height: 150px;
        }
      }
    }
  }
}