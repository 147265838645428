@import "../../../static/scss/media.scss";
@import "../../../static/scss/variables.scss";

@include respond(Medium) {
  div.home-page {
    overflow: hidden;

    &.overflow {
      height: 100%;
      overflow-y: scroll;
    }

    height: 100%;
  }

  div.sport-home-page {
    overflow-y: auto;

    & div.game-table {
      padding: 13px 7px 0px 7px;
    }

    &__top-section {
      margin-top: 0;
    }

    &__bottom-section {
      padding: 0 5px;
    }

    & .noData__container {
      margin-top: 0;

      .noData__img {
        max-width: 120px;
        width: 100%;
      }

      .noData__text {
        margin-top: 15px;
      }
    }
  }
}

@include respond(Small) {
  div.sport-home-page {
    &__bottom-section {
      padding: 0 5px;
      padding: 0;
      height: 30vh;
    }
  }
}