@import '../../static/scss/variables.scss';

.forgot-password {
  .forgot-password_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0, 1) 60%, rgba(0,0,0,0.5) 100% ) !important;
    z-index: 555;

    .forgot-password {
      width: 100%;
      height: auto;
      max-width: 550px;
      background: linear-gradient(to bottom, #00A826 40%, #f2f2f2 0%);
      border-radius: 30px 30px 0 0;
      position: absolute;
      animation: openPopUp .6s ease-in-out forwards;
      padding: 15px 15px 26px;
      z-index: 100000;
      &.closed{
        animation: closePopUp 0.6s ease-in-out forwards;
      }
    }

    @keyframes openPopUp {
      0% {
        bottom: -100%
      }
      100% {
        bottom: 0%
    }
    }

    @keyframes closePopUp {
      0% {
        bottom: 0%
      }
      100% {
        bottom: -100%
      }
    }


    .forgot-password-content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .forgot-password-closeIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    .forgot-password-backIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(180deg);
      background: #000;
      border-radius: 10px;
      padding: 2px 8px;
      height: 20px;
      width: 30px;
    }

    .forgot-password-form__content {
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      padding: 29px 33px;
      &-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
      }
      &-otpTitle{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.0015em;
        color: #000000;
      }
      &-otpBox{
        margin-top: 15px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .otpVerification{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #000000;
        }
        .otpResent{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #00A826;
        }
      }
      &-otherTitle{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 10px;
      }
      .password2step{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 10px;
      }
      .otpInputs{
        display: flex;
        gap: 5px;
        .formik-field{
          input{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 48px;
            text-align: center;
            padding: 0;
          }
        }
      }
      .formik-field {
        height: 40px;
        width: 100%;
        margin-bottom: 25px;
        max-width: unset;
        input{
          padding: 0 12% 0 3%;
        }
  
        input {
          height: 40px;
          width: 100%;
          padding: 0 12% 0 3% !important;
        }
      }
    }    


  .privacyText{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.001em;
    color: #000000;
  }

  .forgot-password-form-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    &__text {
      margin-top: 15px;
      margin-bottom: 7px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .forgot-password-form__privacy-policy {
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: $login-page-input-bg;

    .MuiCheckbox-colorSecondary.Mui-disabled {
      color: $black-text;
      padding-left: 0;
    }

    p {
      color: $login-page-content-text;
    }

    .forgot-password-form__privacy-policy--allocated {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.001em;
      color: #00A826;
      cursor: pointer;
    }
  }
}

  .formik-field__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 10px;
    top: 7px;
  }

  .forgot-password-form__alert {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 15px;
    background-color: $login-page-error;
    top: 0;
    margin-top: 40px;
    width: 95%;
    max-width: 490px;
    z-index: 1000000000;


    & p {
      color: $login-page-content-text;
    }
  }

  .forgot-password-form__log-in-button {
    cursor: pointer;
    height: 40px;
  }

  fieldset legend {
    display: none;
  }

  .MuiFormLabel-root {
    color: #15161B;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 12px) scale(1);
    pointer-events: none;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $black-text;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: $login-page-content-bg;
    display: none;
    padding: 0 8px;
    margin-left: -5px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $login-page-content-active;
  }
}

.forgot-password-form__log-in-button {
  cursor: pointer;
  height: 40px;
  background: #000000 !important;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-transform: uppercase;
  color: #F8F9FB !important;
}

.click-login {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #1A2C6B;
  .click-login-span {
    margin-left: 3px;
    color: #0074E3;
    cursor: pointer;
    text-decoration: underline;
  }
}

.resendOTP {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #0074E3;
}

input:not([value=""]) {
  box-shadow: inset 0 0 0 50px $common-white !important;
}

@media (min-width: 810px) {
  .forgot-password {
    .forgot-password_wrapper {
      background: rgba(0, 0, 0, 0.3) !important;
      .forgot-password {
        width: 95%;
        max-width: 490px;
        background: linear-gradient(to bottom, #00A826 40%, #f2f2f2 0%) !important;
        border-radius: 30px;
        padding: 15px 15px 0px;
        overflow: auto;
        animation: none;
        &.closed{
          animation: none;
        }
      }
    }

    .forgot-password-closeIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;

      img {
        height: 100%;
      }
    }

    .forgot-password-logo {
      background-image: $url-logo-login;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      img {
        height: 100%;
      }
    }

    .forgot-password-form__content {
      .formik-field {
        height: 57px !important;
        width: 100%;
        margin-bottom: 36px;
        max-width: unset;
        margin-top: unset;

        input {
          height: 57px !important;
          width: 100%;
          box-shadow: inset 0 0 0 50px #fff;
        }
      }
    }

    .forgot-password-form__content-text {
      margin-bottom: 10px;
    }

    .forgot-password-form__log-in-button {
      cursor: pointer;
      height: 40px;
      background: #000000;
      border-radius: 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      text-transform: uppercase;
      color: #F8F9FB;
    }

    .forgot-password-form__log-in-button-icon {
      cursor: pointer;
      position: absolute;
      right: 15px;
    }

    .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(20px, 20px) scale(1);
      pointer-events: none;
    }

    .formik-field__icon {
      position: absolute;
      width: 36px;
      height: 27px;
      right: 10px;
      top: 17px;
    }

    .forgot-password-form__privacy-policy {
      display: flex;
      align-items: flex-start;
      padding-right: 15px;
      margin-top: 18px;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 25px;
      letter-spacing: 0.04em;
      color: $login-page-input-bg;

      .MuiCheckbox-colorSecondary.Mui-disabled {
        color: $login-page-input-disabled;
      }

      p {
        color: $login-page-privacy-policy;
      }

      .forgot-password-form__privacy-policy--allocated {
        color: $login-page-content-active;
      }
    }


    .forgot-password-form__alert {
      display: flex;
      position: absolute;
      width: 100%;
      padding: 15px;
      background-color: $login-page-error;
      top: 0;
      margin-top: 40px;
      width: 95%;
      max-width: 490px;
      z-index: 1000000000;


      & p {
        color: $login-page-content-text;
      }
    }


    .MuiFormLabel-root {
      color: #15161B;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: $login-page-error;
    }

    .MuiOutlinedInput-root.Mui-focused {
      background: $login-page-input-bg;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $black-text;
    }

    input:not([value=""]) {
      box-shadow: inset 0 0 0 50px $common-white !important;
    }

    input {
      box-shadow: inset 0 0 0 50px $common-white;
    }

    input:active,
    input:focus {
      box-shadow: inset 0 0 0 50px $common-white ;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      background: $login-page-content-bg;
      padding: 0 8px;
      margin-left: -5px;
    }

    .MuiFormLabel-root.Mui-focused {
      color: $login-page-content-active;
    }
  }
}

.wrongOTP{
  position: absolute;
  bottom: 16px;
  color: #ff4057;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.hidden-custom{
  input[name="password"]
  {
    -webkit-text-security: disc;
  }
  
  input[name="confirmPassword"]
  {
    -webkit-text-security: disc;
  }
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  .forgot-password .forgot-password_wrapper .forgot-password{
    height: 100% !important;
    overflow: auto;
  }
}
@media screen and (max-width: 389px) {
  .forgot-password .forgot-password_wrapper .forgot-password{
    max-height: 100% !important;
    overflow: auto;
  }
}

@media screen and (max-width: 150px) {
  .forgot-password .forgot-password_wrapper .forgot-password {
    height: 100% !important;
    overflow: auto;
  }
}
