.centered {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  &-white {
    background: white;
  }
}

.loaderCont{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position: relative;
   }
   
   .loaderCont::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border-top: 5px solid #636363;
    border-right: 5px solid transparent;
    animation: spinner8217 0.8s linear infinite;
   }
   
   @keyframes spinner8217 {
    to {
     transform: rotate(360deg);
    }
}

.loader-img {
  height: 80px;
  width: 80px;
  background-image: url(../../static/images/loaderFX.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}
