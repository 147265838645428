@import './phone.scss';

.home-page {
  .home-page__content {
    width: 100%;
  }

  &.iPhone {
    display: flex;
  }

  &__header-wrapper {
    background: $home-header-bg;
  }
}

@media (min-width: 1024px) {
  .home-page {
    .game-table__header {
      position: relative;
      top: unset;
      width: unset;
    }

    &__header-wrapper {
      background: unset;
    }
  }
}

.sport-home-page {
  background-color: $primary-background;

  &__bottom-section {
    padding: 0 15px 46px;
  }

  & div.game-table {
    padding: 15px 0 20px 0;
  }
}

header {
  .time-settings {
    .time-settings__img {
      cursor: pointer;
    }
    span.date-small {
      cursor: pointer;
    }
    .date-small__yellow {
      cursor: pointer;
    }
  }
}
.home-page__content {
  .eventCard {

    .eventCard_header {
      cursor: pointer;
    }
    .eventCard_marketTitles {
        cursor: pointer;
    }
    .eventCard_marketButtons_item > div {
        cursor: pointer;
}
  }
}
.home-games {
  .home-games__img-body {
    cursor: pointer;
  }
}

.footer {
  .footer__social-download-title {
    cursor: pointer;
  }
  .footer__social-row-whatsapp {
    cursor: pointer;
  }
  .footer__term .footer__term-steps {
      cursor: pointer;
  }
}