@import "phone.scss";
@import "../../../../static/scss/variables.scss";

.side-account {
  .action-inner-wrapper {
    width: 100%;
  }

  position: absolute;
  right: 0;
  z-index: 100;
  width: 70%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: $user-menu-bg;
  box-shadow: 0px 4px 30px rgba(7, 26, 82, 0.14);

  animation: showright 0.4s linear;
  -webkit-animation: showright 0.4s linear;

  @-webkit-keyframes showright {
    from {
      opacity: 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  .top {
    height: 52px;

    background: $user-menu-bg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;

    .close {
      border-radius: 5px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: $main-text-color;
    }
  }

  .content {
    padding: 0 20px;
    height: 80%;

    .user-data {
      height: 83px;
      margin-top: 30px;
      background: $common-theme-color;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
      border-radius: 5px;

      font-family: Source Sans Pro;
      font-style: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .properties {
        .property {
          font-weight: 600;
          color: $common-white;
          margin: 12px 0;
        }
      }

      .divider {
        width: 1px;
        height: 53px;

        background: #c4c4c4;
        opacity: 0.1;
      }

      .values {
        .value {
          font-weight: bold;
          color: $common-active-color;
          margin: 12px 0;

          &:nth-child(2) {
            color: $common-active-color;
          }

          a {
            color: $common-active-color;
          }
        }
      }
    }

    .list {
      margin-top: 18px;

      .action {
        position: relative;

        img {
          height: 15px;
          width: auto;

          &.unsettled {
            height: 13px;
          }

          &.setValues {
            height: 11px;
          }

          &.out-icon {
            height: 11px;
          }
        }
      }
    }
  }

  .icon.side-rules-feedback:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    background-image: url('../../../../static/images/actions/feedback.svg');
    color: #0074e3;
    background-position: center;
    height: 20px;
    width: 13px;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1024px) {
  .depositWithdrawal-container{
    display: flex;
    justify-content: space-around;
    &__button{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 108px;
      background: #FF7A40;
      border-radius: 4px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #fff;
    }
  }
}
