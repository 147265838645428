.formik-field {
  width: 100%;
  max-width: 360px;
  margin-top: 10px;
  position: relative;
  color: #000;
  background: #fff;

  &__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 15px;
    top: 17px;
    &:hover {
      cursor: pointer;
    }
    
    &.text {
      right: unset;
      top: 19px!important;
      left: 15px;
      z-index: 1;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1876em;
      color: #000;
      @media (max-width: 810px) {
        top: 9px!important;
      }
    }

    &.code {
      right: unset;
      left: 48px;
      top: 20px!important;
      width: 8px!important;
      height: 17px!important;
      z-index: 1;
      @media (max-width: 810px) {
        top: 9px!important;
      }
    }
    &.line {
      width: 1px!important;
      background: #d9d9d9;
      height: 98%!important;
      top: 0!important;
      transform: translate(10px, 1px);
    }
  }
  &__minMax{
    position: absolute;
    width: auto;
    height: 24px;
    right: 15px;
    top: 17px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #999999;
    &:hover {
      cursor: pointer;
    }
  }
  &__rupee{
    position: absolute;
    width: auto;
    left: 12px;
    top: 12px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 18px;
    color: #15161b;
  }

}

.MuiFormControl-root {
  width: 100%!important;
}

.countryCode {
  #formic-field, #mobileNumber {
    padding-left: 75px!important;
  }
  label {
    transform: translate(75px, 20px) scale(1)!important;
    @media (max-width: 810px) {
      transform: translate(75px, 10px) scale(1)!important;
    }
  }
}

.forgot-password {
  .formik-field__icon {
    &.code {
      top: 9px!important;
    }
    &.line {
      top: 0px!important;
      transform: translate(10px, 1px);
    }
  }
}