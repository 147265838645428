@import '../../static/scss/variables.scss';

.footer-bar {
  position: absolute;
  bottom: 0;
  height: $footer-bar-height;
  width: 100%;
  border-radius: 4px;
  background: $footer-bar-bg;
  box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto';
  &.android {
    position: unset;
    bottom: unset;
    height: 60px;
  }
  .footer-bar__navigation {
    display: none;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    overflow-x: scroll;
    &-item {
      width: 100%;
      min-width: max-content;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: all 0.5s;
      padding: 0 5px;
      &-img {
        width: 24px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        transition: all 0.5s;
      }
      span {
        font-weight: 700;
        font-size: 13px;
        text-transform: uppercase;
        color: $footer-bar-color;
        text-align: center;
        transition: all 0.5s;
      }
      &.active{
        background-color: #000;
        span{
          color: white;
        }
      }
    }
  }
  .footer-bar__navigation::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  .footer-bar__bet-slip {
    width: 100%;
    height: 60px;
    background: $footer-bar-bg-secondary;
    position: absolute;
    top: -60px;
    left: 0px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    z-index: 2;
    &.android {
      position: unset;
      top: unset;
    }
    &-title-block {
      display: flex;
      &-img {
        width: 16px;
        height: 20px;
        margin-right: 10px;
      }
      span {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: $footer-bar-color-secondary;
      }
    }
    &-dropdawn-block {
      display: flex;
      &-title, &-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 40px;
      }
      &-title {
        width: 62px;
        height: 24px;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        color: $footer-bar-color-secondary;
        margin-right: 5px;
      }
      &-arrow {
        width: 31px;
        height: 24px;
      }
    }
    &.disabled {
      display: none;
    }
  }
}

@media (min-width: 1024px) {
  .footer-bar {
    display: none;
  }
}
