@import '../../../../../static/scss/media';
@import '../../../../../static/scss/variables';

.update-popup {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &-content {
    display: flex;
    background: linear-gradient(0deg, rgba(0,0,0, 1) 60%, rgba(0,0,0,0.5) 100% ) !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 555;
  }
  &-header {
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 550px;
    background: #fff;
    position: absolute;
    animation: openPopUp .6s ease-in-out forwards;
    border-radius: 30px 30px 0 0;
    padding: 15px 15px 25px;
    z-index: 1000;
    &__close {
      display: flex;
      justify-content: flex-end;
      margin-top: 17px;
      cursor: pointer;
    }
    &__rockert {
      display: flex;
      justify-content: center;
    }
    &.closed{
      animation: closePopUp 0.6s ease-in-out forwards;
    }
  }
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 55px 0 55px;
    font-family: 'Roboto';
    font-style: normal;
    color: $black-text;
    margin-bottom: 27px;
    h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: $black-text;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.0015em;
    }
  }
  &__submit-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: #000000;
    white-space: nowrap;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    margin: 0 9px 0 9px;
    color: #F8F9FB;
  }

  @keyframes openPopUp {
    0% {
      bottom: -100%
    }
    100% {
      bottom: 0%
    }
  }
  
  @keyframes closePopUp {
    0% {
      bottom: 0%
    }
    100% {
      bottom: -100%
    }
  }
}

.update-popup__submit-button.arrow::after {
  content: "";
  position: absolute;
  display: block;
  right: 110px;
  width: 15px;
  height: 14px;
  background-image: url(../arrowIcon.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.update-popup__submit-button.loading::after {
  content: "";
  position: absolute;
  display: block;
  right: 100px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  animation: loadingSpinner 1s linear infinite;
}

@keyframes loadingSpinner {
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 810px) {
  .update-popup {
    &-content {
      background: rgba(0, 0, 0, 0.3) !important;
      z-index: 555;
    }
    &-header {
      width: 95%;
      max-width: 490px;
      border-radius: 30px;
      overflow: auto;
      animation: none;
      &.closed{
        animation: none;
      }
    }
  }

  .update-popup__submit-button.arrow::after {
    right: 160px !important;
  }

  .update-popup__submit-button.loading::after {
    right: 150px !important;
  }
}

@media (min-width: 425px) {
  .update-popup__submit-button.arrow::after {
    right: 130px;
  }

  .update-popup__submit-button.loading::after {
    right: 120px;
  }
}

@media (min-width: 768px) {
  .update-popup__submit-button.arrow::after {
    right: 190px;
  }

  .update-popup__submit-button.loading::after {
    right: 180px;
  }
}
