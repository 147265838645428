@import '../../../static/scss/media.scss';
@import '../../../static/scss/variables.scss';

@media all and (max-width: 1024px) {
  div.slot {
    overflow: hidden;
    &__header {
      display: none;
    }
    &__body {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 8px;
    }
    &__wraper {
      padding: 0;
      overflow-y: scroll;
        &.slot__in-page-home {
                height: calc(100vh - 143px);
                &.isMessages {
                    height: calc(100vh - 143px);
                }
            }
            &.slot__in-page-slots {
              height: calc(100vh - 107px);
              &.isMessages {
                height: calc(100vh - 143px);
            }
            }
    }
    &__deception-top {
     display: none;
    }
    &__fix-top {
      position: relative;
      width: 100%;
      z-index: 10;
      &::before {
        display: none;
      }
    }
  }
}

@include respond(Medium) {
  div.slot {
    &.slot__in-page-home {
          height: calc(100vh - 91px);
          &.isMessages {
              height: calc(100vh - 146px);
      }}
      &.slot__in-page-slots {
          height: 100%;
      }
    padding: 0;
    &__body {
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 10px;
      grid-row-gap: 9px;
      width: 100%;
      max-width: 375px;
      margin: 0 auto;
      padding-top: 20px;
      &-cards {
        &-wrapp {
          border-radius: 5px;
          max-height: unset;
          //height: 176px;
          width: unset;
          img{
            width: 100%;
            aspect-ratio: 1/1;
            min-height: unset !important;
          }
          &:hover {
            .slot__body-cards-btn {
              opacity: 0;
            }
            img {
              min-height: 130px;
            }
          }
        }
        img {
          min-height: 130px;
        }
        .slot__body-cards-name {
          font-weight: 400;
          margin-top: 5px;
        }
      }
    }
  }
}
