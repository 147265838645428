@import '../../../../static/scss/variables.scss';
@import "../../../../static/scss/media.scss";

.home-games {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 14px;
  color: $live-casino-color;
  margin-top: 20px;

  .home-games__header {
    background: $live-casino-bg;
    border-radius: 20px 20px 0px 0px;
    height: 180px;
    padding: 20px 20px 0 20px;
    display: flex;
    position: relative;
    &-info-wrapper {
      width: calc(100% - 150px);
    }
    &-title {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 8px;
    }
    &-sub-title, &-info-desk {
      display: none;
    }
    &-info {
      margin-bottom: 15px;
    }
    &-img {
      position: absolute;
      top: -35px;
      right: 0;
      bottom: 0;
      max-width: 181px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .home-games__header-celebrity {
    background: $live-casino-bg;
    border-radius: 20px 20px 0px 0px;
    height: 100px;
    padding: 20px 20px 0 20px;
    display: flex;
    position: relative;
    &-info-wrapper-celebrity {
      width: available;
    }
    &-title {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 8px;
    }
    &-sub-title, &-info-desk {
      display: none;
    }
    &-info {
      margin-bottom: 15px;
    }
    &-img {
      position: absolute;
      top: -35px;
      right: 0;
      bottom: 0;
      max-width: 181px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .home-games__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    width: 100%;
    justify-content: space-between;
    margin-top: -55px;
    position: relative;
  }

  .home-games__item {
    width: calc((100vw - 30px) / 2);
    margin-bottom: 5px;
  }

  .home-games__img-body {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      // height: 216px;
      // object-fit: cover;
    }
  }

  .home-games__button {
    background: black;
    border-radius: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $live-casino-button-color;
    font-weight: 600;
    font-size: 14px;
    margin: 10px 10px 0 10px;
    cursor: pointer;

    img {
      margin-right: 10px;
    }

    span {
      text-transform: uppercase;
    }
  }
  .home-games__button-info {
    display: none;
  }
}

@media (min-width: 501px) {
  .home-games {
    .home-games__item {
      width: calc((100vw - 50px) / 3);
    }
  }
}


@media (min-width: 1024px) {
  .home-games {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 30px;
    color: $live-casino-secodary;
    margin-top: unset;
    padding: 65px 0 70px 0;

    .home-games__header {
      background: transparent;
      border-radius: unset;
      height: unset;
      padding: unset;
      display: flex;
      position: relative;
      justify-content: space-between;
      &-info-wrapper {
        width: calc(100% - 370px);
      }
      &-title {
        display: block;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 8px;
      }
      &-sub-title {
        display: block;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 8px;
      }
      &-info {
        display: none;
      }
      &-info-desk {
        display: block;
        font-family: 'Lato';
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 35px;
      }
      &-img {
        top: -120px;
        max-width: 264px;
      }
    }

    .home-games__header-celebrity {
      background: transparent;
      border-radius: unset;
      height: unset;
      padding: unset;
      display: flex;
      position: relative;
      justify-content: space-between;
      &-info-wrapper-celebrity {
        width: calc(100% - 370px);
      }
      &-title {
        display: block;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 8px;
      }
      &-sub-title {
        display: block;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 8px;
      }
      &-info {
        display: none;
      }
      &-info-desk {
        display: block;
        font-family: 'Lato';
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 35px;
      }
      &-img {
        top: -120px;
        max-width: 264px;
      }
    }

    .home-games__list {
      display: flex;
      flex-wrap: wrap;
      padding: unset;
      width: 100%;
      justify-content: space-between;
      margin-top:unset;
      position: relative;
    }

    .home-games__button-wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .home-games__button {
      background: $live-casino-bg;
      border-radius: 10px;
      height: 40px;
      width: 270px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $live-casino-button-color;
      font-weight: 600;
      font-size: 14px;
      margin: unset;
      cursor: pointer;

      img {
        margin-right: 10px;
      }

      span {
        text-transform: uppercase;
      }
    }
    .home-games__button-info {
      display: block;
      font-family: 'Lato';
      font-weight: 600;
      font-size: 16px;
      span:last-child {
        color:  $live-casino-action-color
      }
    }
  }
  .home-games.cards, .home-games.slotGames {
    background-color: $live-casino-bg-secondary;
  }
}

@media (min-width: 1024px ) {
  .home-games {
    .home-games__item {
      width: calc((100vw - 100px) / 5);
      max-width: 270px;
    }
  }
}
.carousel-container {
  margin: 0 auto;
  top: -13px;
  position: relative;
}

.video-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: calc((100vw - 40px) / 3);
  position: relative;

  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
}
.playVideoIcon{
  position: absolute;
  content: url('../../../../static/images/icons/playVideoIcon.svg');
  z-index: 3;
  cursor: pointer;

  &:hover{
    scale: 1.1;
  }
  &:active{
    scale: 1;
  }
}
.iframeVideoBG{
  background-color: rgba(0, 0, 0, 0.65);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  &_closeIcon{
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    z-index: 7;
  }
}
.iframeVideo{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  height: 85vh;
  width: 85vw;
  z-index: 6;
  background-color: black;
}
@media (min-width: 1024px) {
  .video-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc((100vw - 50px) / 5);
  }
  .carousel-container {
    top: 0;
  }
  .playVideoIcon{
    scale: 1.5;

    &:hover{
      scale: 1.7;
    }
    &:active{
      scale: 1.5;
    }
  }
}
@media (min-width: 1440px) {
  .video-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc((1440px - 50px) / 5);
  }
}

//.home-games {
//    &__title {
//        font-family: Source Sans Pro;
//        font-style: normal;
//        font-weight: 600;
//        font-size: 22px;
//        line-height: 28px;
//        color: $common-theme-color;
//
//        background-color: $primary-background;
//        //position: fixed;
//        //width: calc(100vw - 245px);
//        height: 35px;
//        margin-top: 20px;
//        padding-left: 15px;
//
//        &:before {
//            content: '';
//            position: absolute;
//            top: -20px;
//            left: 0;
//            width: 100%;
//            // background-color: black;
//            background: $primary-background;
//            height: 20px;
//        }
//    }
//
//    &__list {
//        width: 100%;
//        //padding-top: 60px;
//        display: grid;
//        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
//        justify-content: space-between;
//        grid-column-gap: 1vw;
//        grid-row-gap: 20px;
//
//        padding: 0 15px 15px;
//    }
//
//    &__item {
//        height: 114px;
//        border-radius: 15px;
//        overflow: hidden;
//        cursor: pointer;
//    }
//
//    &__img-body {
//        height: 100%;
//        background-color: transparent;
//        background-repeat: no-repeat;
//        background-position: center;
//        background-size: auto 100%;
//        position: relative;
//
//        &:hover {
//            height: 110%;
//            transition: height 0.5s;
//
//            /*             &::before {
//                content: '';
//                background: rgba(0, 0, 0, 0.6);
//                position: absolute;
//                top: 0;
//                left: 0;
//                width: 100%;
//                height: 100%;
//            } */
//        }
//    }
//}
