@import '../../../../static/scss/media.scss';
@import '../../../../static/scss/variables.scss';



@include respond(Large) {
  div.betslip.sportEventDetailsBetslip {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    // bottom: 0;
  }

  div.betslip {

    &__header {
      padding: 8px 10px;
      height: 60px;
      background: #00A826;
      border-radius: 10px 10px 0 0;
      display: flex;
      padding: 0 31px;
      &-name{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-left: 10px;
      }
    }

    &.open {
      bottom: 0;
      transition: all 0.4s;
      animation: openBet .4s ease;
      overflow: auto;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border: none;
        display: inline-block;
        position: absolute;
        top: 3px;
        opacity: 1;
        transition: all 0.8s;
      }
    }

    &.close {
      transition: all .5s;
      bottom: -100%;

      &:before {
        content: '';
        width: 100%;
        height: 0%;
        opacity: 0;
        display: inline-block;
      }
    }

    &.blackout.open,
    &.loading.open {
      &:before {
        height: 100%;
        position: absolute;
        transition: none;
      }

      .wrapper {
        position: absolute;
        bottom: 0;
      }
    }

    .wrapper {
      height: auto;
      position: absolute;
      bottom: 0;
      width: 100%;
    }


    &__body {
      font-size: 14px;
      line-height: 20px;
      height: 100%;
      overflow-y: auto;
      padding: 0 31px;
      min-height: 501px;
      // animation: openBet .4s ease;

      &.back {
        background: $sport-event-details-betslip-content-back-bg;
      }

      &.lay {
        background: $sport-event-details-betslip-content-lay-bg;
      }

      &-name,
      &-details {
        font-size: 14px;
        line-height: 20px;
        padding-left: 10px;
      }

      .betslip__body-details-name {
        width: 30%;
      }

      &-place-bet {
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        border-radius: 5px 5px 0 0;

        &_stake {
          .betslip__body-place-bet_group-title {
            width: 104%;
          }
        }

        .betslip__body-place-bet_reset {
          margin-top: 25px;
        }

        .betslip__body-place-bet_submit {
          margin-top: 25px;
        }
      }

      &-section {
        padding: 0 10px 45px 10px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $sport-event-details-betslip-content-name;

        &-tr {
          display: flex;
          justify-content: space-between;
        }

        .outcome {
          &.plus {
            color: $success-green;
          }

          &.minus {
            color: $sport-event-details-betslip-error;
          }
        }
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 1300px) {
  div.betslip {
    &.open {
      &:before {
        height: 100vh;
        position: absolute;
        top: 0;
      }
    }

    &.blackout.open,
    &.loading.open {
      &:before {
        height: 100%;
        position: absolute;
        transition: none;
      }

      .wrapper {
        position: absolute;
        top: 10%;
        left: 30%;
        transform: none;
      }
    }
  }

  div.betslip.sportEventDetailsBetslip {
    .wrapper {
      max-width: 500px;
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes openBet {
  0%{
    transform: translateY(100%);
    background:rgba(0, 0, 0, 0.0)
  }
  100%{
    transform: translateY(0%);
    background:rgba(0, 0, 0, 0.6)
  }
}

@keyframes openConfirmBet {
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(0%);
  }
}

@keyframes closeBet {
  0%{
    transform: translateY(0%);
  }
  100%{
    transform: translateY(100%);
  }
}

@keyframes closeConfirmBet {
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(100%);
  }
}

.betPlacingPop-up__body__buttons{
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 15px;
  border-radius: 40px;
  background: #EFF3F6;
  &-button{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.001em;
    color: #000000;
    height: 44px;
    background: #EFF3F6;
    border-radius: 40px;
    cursor: pointer;

    &-active{
      background: #00A826;
      box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
      border-radius: 40px;
      color: #FFFFFF;
      height: 44px;
      width: 50%;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.001em;
      justify-content: center;
      animation: activeButton 0.5s ease;
      cursor: pointer;
    }
  }
}

.betslipTeamMarket{
  display:flex;
  justify-content: space-between;
  align-items:center;
  margin-bottom: 17px;
  .betslip-team{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .betslip-maxMarket{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
  }
}

.betslipButtonsBox{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.betslipButtonsContainer{
  background: #F5F5F5;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0px 0px 5px 5px;
  padding: 21px 18px;
  border-radius: 0 0 5px 5px;
}

.betslipConfirmBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
  &-confirm{
    display: flex;
    align-items: center;
    div{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.001em;
      color: #000000;
      // margin-left: 5px;
    }
  }
}

.placeBetTitle{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #F8F9FB;
  img{
    margin-left: 20px;
  }
}
.placeBetDescr{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #11DB3F;
}

.loadingPlaceBet{
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.loaderBet{
  position: relative;
  width: 200px;
  height: 200px;
  .loaderBetTime {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    color: #00A826;
    font-weight: bold;
  }
}


.spinnerBet{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  border-bottom: 5px solid #a7a7a7;
  border-right: 5px solid transparent;
  box-sizing: border-box;
  animation: spin 1.5s linear infinite;
  position: absolute;
  top: 25%;
  left: 25%;
}

.spinnerBetSuccess{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: spinSuccess 1.5s linear;
  position: absolute;
  top: 25%;
  left: 25%;
}

.successBet{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 25%;
  left: 25%;
  background: rgba(0, 168, 38, 1);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spinSuccess {
  0% {
    transform: rotate(0deg);
    border-bottom: 5px solid #a7a7a7;
    background: rgba(0, 168, 38, 0);
  }
  90% {
    transform: rotate(-360deg);
    border: 5px solid #00A826;
    background: rgba(0, 168, 38, 1);
  }
  100% {
    border: 5px solid #00A826;
    background: rgba(0, 168, 38, 1);
  }
}

.loadingPlaceBetIcon{
  width: 60px;
  height: 60px;
  position: absolute;
  top: 35%;
  left: 35%;
}

.loader-title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #15161B;

}
.loader-descr1{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #555555;
  margin: 10px 0;
}
.loader-descr2{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #999999;
}

.success-checkmark {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 25%;
  left: 25%;

  .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4CAF50;

      &::before {
          top: 3px;
          left: -2px;
          width: 30px;
          transform-origin: 100% 50%;
          border-radius: 100px 0 0 100px;
      }

      &::after {
          top: 0;
          left: 30px;
          width: 60px;
          transform-origin: 0 50%;
          border-radius: 0 100px 100px 0;
          animation: rotate-circle 4.25s ease-in;
      }

      &::before, &::after {
          content: '';
          height: 100px;
          position: absolute;
          background: #FFFFFF;
          transform: rotate(-45deg);
      }

      .icon-line {
          height: 5px;
          background-color: #4CAF50;
          display: block;
          border-radius: 2px;
          position: absolute;
          z-index: 10;

          &.line-tip {
              top: 46px;
              left: 14px;
              width: 25px;
              transform: rotate(45deg);
              animation: icon-line-tip 0.75s;
          }

          &.line-long {
              top: 38px;
              right: 8px;
              width: 47px;
              transform: rotate(-45deg);
              animation: icon-line-long 0.75s;
          }
      }

      .icon-circle {
          top: -4px;
          left: -4px;
          z-index: 10;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: absolute;
          box-sizing: content-box;
          border: 4px solid rgba(76, 175, 80, .5);
      }

      .icon-fix {
          top: 8px;
          width: 5px;
          left: 26px;
          z-index: 1;
          height: 85px;
          position: absolute;
          transform: rotate(-45deg);
          background-color: #FFFFFF;
      }
  }
}



@keyframes rotate-circle {
  0% {
      transform: rotate(-45deg);
  }
  5% {
      transform: rotate(-45deg);
  }
  12% {
      transform: rotate(-405deg);
  }
  100% {
      transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  54% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  70% {
      width: 50px;
      left: -8px;
      top: 37px;
  }
  84% {
      width: 17px;
      left: 21px;
      top: 48px;
  }
  100% {
      width: 25px;
      left: 14px;
      top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  65% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  84% {
      width: 55px;
      right: 0px;
      top: 35px;
  }
  100% {
      width: 47px;
      right: 8px;
      top: 38px;
  }
}

// .checkbox-wrapper-31:hover .check {
//   stroke-dashoffset: 0;
// }

// .checkbox-wrapper-31 {
//   position: relative;
//   display: inline-block;
//   width: 20px;
//   height: 20px;
// }

// .checkbox-wrapper-31 .background {
//   fill: #ccc;
//   transition: ease all 0.6s;
//   -webkit-transition: ease all 0.6s;
// }

// .checkbox-wrapper-31 .stroke {
//   fill: none;
//   stroke: #fff;
//   stroke-miterlimit: 10;
//   stroke-width: 2px;
//   stroke-dashoffset: 100;
//   stroke-dasharray: 100;
//   transition: ease all 0.6s;
//   -webkit-transition: ease all 0.6s;
// }

// .checkbox-wrapper-31 .check {
//   fill: none;
//   stroke: #fff;
//   stroke-linecap: round;
//   stroke-linejoin: round;
//   stroke-width: 2px;
//   stroke-dashoffset: 22;
//   stroke-dasharray: 22;
//   transition: ease all 0.6s;
//   -webkit-transition: ease all 0.6s;
// }

// .checkbox-wrapper-31 input[type=checkbox] {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   left: 0;
//   top: 0;
//   margin: 0;
//   opacity: 0;
//   -appearance: none;
//   -webkit-appearance: none;
// }

// .checkbox-wrapper-31 input[type=checkbox]:hover {
//   cursor: pointer;
// }

// .checkbox-wrapper-31 input[type=checkbox]:checked + svg .background {
//   fill: #000;
// }

// .checkbox-wrapper-31 input[type=checkbox]:checked + svg .stroke {
//   stroke-dashoffset: 0;
// }

// .checkbox-wrapper-31 input[type=checkbox]:checked + svg .check {
//   stroke-dashoffset: 0;
// }

.betslip-showOdds{
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin-bottom: 9px;
  padding: 15px 15px 19px 19px;
  &-titleContainer{
    display: flex;
    justify-content: space-between;
    &-group{
      display: flex;
      width: 130px;
      justify-content: space-around;
    }
  }
  &-oddsName{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #000000;
  }
  &-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #000000;
  }
  &-oddsBox{
    display: flex;
    width: 100%;
    height: 45px;
    margin-bottom: 3px;
    position: relative;
    &-team{
      background: #F7F7F7;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.0015em;
      color: #000000;
      width: 100%;
      span{
        display: inline-block;
        margin-top: -5px;
      }
    }
    &-oddsItems{
      display: flex;
      justify-content: space-between;
      width: 200px;
      height: 100%;

      &-itemBack{
        width: 50%;
        margin: 0 1.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #72BBEF;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
      }
      &-itemLay{
        width: 50%;
        margin: 0 1.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FAA9BA;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
}

.betSlipFoot{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.betSlipFooter{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
}
.betSlipFooterAddMore{
  width: 80%;
  height: 34px;
  background: #F3FFF6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00A826;
  margin: 15px 0;
  cursor: pointer;
}


.betSlipExposure{
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  left: 14px;
  font-size: 10px;
  font-family: "Lato";
  font-weight: 700;
  height: 63px;
  align-items: end;
  justify-content: space-between;
  top: 67px;
  align-items: start;
  left: 35px;
  .outcome {
    &.plus {
      color: $success-green;
    }

    &.minus {
      color: $sport-event-details-betslip-error;
    }
  }
}

.betSlipCondirm{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  &-wrapper{
    width: 100%;
    position: absolute;
    background: linear-gradient(to bottom, #00A826 60%, #f2f2f2 0%);
    padding: 16px 15px 21px 15px;
    bottom: 0;
    overflow: hidden;
    border-radius: 20px 20px 0px 0px;
    animation: openConfirmBet .5s ease;
    &-content{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

    }
  }
}
.closeConfirm{
  animation: closeConfirmBet .5s ease;
}

.betslipAnimation{
  animation: opacityBetSlip .5s ease;
}

@keyframes opacityBetSlip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.betPlacingCondirm-header{
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  &-team{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
  &-descr{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
    margin-top: 4px;
  }
}
.betPlacingCondirm-body{
  width: 100%;
  height: 219px;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-top: 31px;
  padding: 27px;
  &-table{
    width: 100%;
    height: 72px;
    border: 1px solid #000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    &-content{
      display: flex;
      flex-direction:column;
      align-items: center;
      width: 32%;
      height: 80%;
      justify-content: center;
      &-title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #9DA2AF;
      }
      &-number{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
  }
}
.borderCell{
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid rgba(0, 0, 0, 0.2);
}

.showMyBetsLength{
  border-radius: 50px;
  // height: 18px;
  // width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.001em;
  color: #FFFFFF;
  margin-left: 5px;
  padding: 1px 7px;
}

@keyframes activeButton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.singleBet{
  height: 0px;
}

.tripleBet{
  height: 107px;
}

@media (max-width: 1023px) and (max-height: 730px) {
  div.betslip .wrapper{
    height: 95%;
    overflow: auto;
  }
  .betslip-showOdds-oddsName {
    font-size: 11px;
  }
  .betslip-showOdds {
    height: fit-content;
    &-title{
      font-size: 11px;
    }
    &-oddsBox{
      height: 35px;
      &-team{
        font-size: 11.5px;

      }
    }
    .betslip-showOdds-oddsBox-oddsItems-itemLay {
      .eventCard_marketButtons_item-lay{
        height: 35px;
      }
    }
    .betslip-showOdds-oddsBox-oddsItems-itemBack {
      .eventCard_marketButtons_item-back{
        height: 35px;
      }
    }
    &-team{
      font-size: 11px;
    }
  }
  .betSlipExposure{
    top: 64px;
    height: 48px;
    font-size: 9px;
  }
  .div.betslip__header {
    height: 45px;
  }
  .betPlacingPop-up__body__buttons-button-active {
    height: 35px;
  }
  .betPlacingPop-up__body__buttons-button {
    height: 35px;
  }
  div.betslip__body-place-bet{
    padding-bottom: 0;
  }
  .betslip__body-place-bet_submit {
    height: 60px;
  }
  .betslip__body-place-bet_item {
    height: 25px;
  }
  .betslip__body-place-bet_itemm{
    height: 25px;
  }
  .betslipConfirmBox{
    margin-top: 10px;
  }
  .tripleBet{
    height: 87px;
    top: 63px;
  }
  .singleBet{
    height: 0px;
  }
}

@media screen and (max-height: 830px) {
  div.betslip .wrapper{
    height: 95%;
    overflow: auto;
  }
  .betslip-showOdds {
    height: fit-content;
  }
}
