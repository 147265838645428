@import '../../static/scss/variables.scss';

.drop-down-login {
  ul, li {
    list-style: none;
    margin: unset;
  }
    width: min-content;
    right: 0;
    top: 21px;
    position: absolute;
    z-index: 1000;
    .drop-down-login__dropdown {
      width: 100%;
      height: 45px;
      &-content {
        display: flex;
        height: 93%;
        width: 98%;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border-left: 1px solid #000000;
        border-radius: 5px;
        padding: 10px 18px;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        margin-top: 1px;

        cursor: pointer;
        .drop-down-login__dropdown-content-image {
          transition: all 0.3s;
          img {
            width: 10px;
            height: 10px;
          }
          &.active {
            transform: rotateX(-180deg);
          }
        }
      }
    }
  }
.drop-down-login__dropdown-content-items {
  width: min-content;
  background-color: $common-white;
  padding: 10px;
  white-space: nowrap;
  position: absolute;
  z-index: 100;
  left: -89px;
  top: -1px;
  transform: translateY(-50%) scaleY(0);
  transition: all 0.3s;
  overflow: hidden;
  border: 1px solid #000;
  opacity: 0;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  .drop-down-login__dropdown-content-items-one {
    min-width: min-content;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 10px;
    border-radius: 5px;
    background-color: #F5F5F5;
    cursor: pointer;
    margin-bottom: 5px;
    transition: all 0.3s;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    :last-child {
      margin-bottom: 0;
    }
    .phoneIcon {
      width: 15px;
      height: 24px;
      margin-right: 5px;
      background-image: url(./phoneLogin.svg);
    }
    .userIcon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
      background-image: url(./userIconLogin.svg);
    }
    &.active {
      .phoneIcon {
        width: 15px;
        height: 24px;
        margin-right: 5px;
        background-image: url(./phoneLoginWhite.svg);
      }
      .userIcon {
        margin-right: 5px;
        width: 24px;
        height: 24px;
        background-image: url(./userIconLoginWhite.svg);
      }
      color: #fff;
      background-color: #00A826;
    }
    &:hover {
      transform: scale(1.01);
    }
    &:active {
      transform: scale(1.01);
    }
  }
  &.active {
    z-index: 1;
    transform: translateY(0%) scaleY(1);
    height: auto;
    opacity: 1;
    width: auto;
  }
}

.drop-down-login__dropdown-img {
  margin-right: 10px;
}

@media (min-width: 810px) {
  .drop-down-login__dropdown {
    height: 62px !important;
  }
}
