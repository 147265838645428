@import '../../static/scss/variables.scss';

.ticker-wrapper {
  //position: sticky;
  //top: 0;
  background: $header-background;
  width: 100%;
  padding: 0 11px 5px 11px;
  z-index: 1;
  .ticker-body{
    position: relative;
    padding-right: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 13px;
  }
  .ticker {
    color: white;
    padding: 10px 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    overflow: hidden;
    width: 100%;
    height: 36px;

    &__line {
      position: absolute;
      top: 0px;
      left: 100%;
      margin-top: 8px;
    }

    &__item {
      white-space: nowrap;

      &--next {

        &::before {
          content: "|";
          margin-right: 20px;
          margin-left: 20px;
        }
      }
    }

    &.phone {
      position: relative;
    }
  }
  .header_notificationBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
    padding: 10px 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    &-text{

      &-underline{
        color: green;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &-close{
      cursor: pointer;
      color: white;
      width: 25px;
      position: absolute;
      right: 5px;
      bottom: 8px;
      font-size: 18px;
    }

  }
}


@media (min-width: 1024px) and (max-width: 1550.5px) {
  .ticker-wrapper {
    //position: sticky;
    //top: 0;
    background: $header-background;
    width: 100%;
    padding: 5px 15px 5px 30px;
    z-index: 1;
    .ticker-body{
      position: relative;
      padding-right: 40px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 13px;
    }
    .ticker {
      color: white;
      padding: 10px 20px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      overflow: hidden;
      width: 100%;
      position: relative;
      height: 38px;

      &__line {
        position: absolute;
        top: 0px;
        left: 100%;
        margin-top: 8px;
      }

      &__item {
        white-space: nowrap;

        &--next {

          &::before {
            content: "|";
            margin-right: 20px;
            margin-left: 20px;
          }
        }
      }

      &.phone {
        position: relative;
      }
    }
    .header_notificationBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: white;
      padding: 10px 20px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      &-text{

        &-underline{
          color: green;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      &-close{
        color: white;
        width: 25px;
        position: absolute;
        right: 5px;
        bottom: 8px;
        font-size: 18px;
      }

    }
  }
}

@media (min-width: 1550.5px) {
  .ticker-wrapper {
    background: $header-background;
    width: 500px;
    overflow: hidden;
    padding: unset;
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    .ticker-body{
      position: relative;
      padding-left: 15px;
      padding-right: 40px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
    }
    .ticker {
      color: white;
      padding: 10px 20px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      overflow: hidden;
      width: 100%;
      height: 38px;
      position: relative;

      &__line {
        position: absolute;
        top: 0px;
        left: 100%;
        margin-top: 10px;
      }

      &__item {
        white-space: nowrap;

        &--next {

          &::before {
            content: "|";
            margin-right: 20px;
            margin-left: 20px;
          }
        }
      }

      &.phone {
        position: relative;
      }
    }
    .header_notificationBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: white;
      padding: 10px 20px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      &-text{

        &-underline{
          color: green;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      &-close{
        color: white;
        width: 25px;
        position: absolute;
        right: 5px;
        bottom: 8px;
        font-size: 18px;
      }

    }
  }
}
