@import "../../../../static/scss/variables.scss";
@import "../../../../static/scss/media.scss";


.time-settings {
  position: relative;
  margin-left: 5px;
  display: flex;
  align-items: baseline;
  &__img {
    width: 12px;
    height: 16px ;
    margin-right: 5px;
    position: relative;
    top: 2px;
    display: inline-block;
  }

  .date-small{
    color: $search-color;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    margin-right: 3px;
    &__yellow{
      color: $search-color;
      cursor: pointer;
      &-symbol{
        display: inline-block;
        margin: 0 5px;
      }
      &-symbolright{
        display: inline-block;
        margin-left: 10px;
      }
      &-arrow{
        &::after{
          content: '\2304';
          position: absolute;
          top: -6px;
          right: -3px;
        }
      }
      &:hover{
        color: $search-color;
      }
    }
  }
  .date-big{
    color: $search-color;
  }

  .timeList {
    position: absolute;
    right: 0;
    top: 25px;
    list-style: none;
    margin: 0;
    background: $header-time-settings-bg;
    border-radius: 10px;
    width: 300px;
    height: 0;
    padding: 20px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: $header-time-settings-color;
    transition: all 0.3s;
    transform: translateY(-100%);
    opacity: 0;

    &__option {
      padding: 0 15px;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s;
      margin-top: 1px;
      opacity: 0;
      height: 0;
      overflow: hidden;
      &:hover {
        background-color:  #f5f5f5;
      }
      &:active {
        transform:  scale(0.99);
        background-color:  $header-time-settings-active-bg;
        color: $header-time-settings-active-color;
      }
      &.active {
        background-color:  $header-time-settings-active-bg;
        color: $header-time-settings-active-color;
      }
    }
  }
  .timeList.active {
    z-index: 11;
    height: auto;
    opacity: 1;
    transform: translateY(0);
    .timeList__option {
      padding: 13px 15px;
      opacity: 1;
      height: auto;
    }
  }
}
@media (max-width: 1023.5px) {
  .time-settings {
    display: none;
  }
}
