@import "./static/scss/media.scss";
@import "./static/scss/variables.scss";
@import "./tailwind.scss";

* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#modal-root {
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  margin: 0 auto;
}

// .main-content-wrapper:has(.depositPage, .transactions-new){
//   background-color: white;
// }

.main-content-wrapper>div:not(.game,
      .sports-event-detail,
      .home-page,
      .exchange-games,
      .grouped-games,
      .slot,
      .casinoGame,
      .betby-sportsbook,
      .transactions-new,
      .depositPage,
      .withdrawalNew,
      .exchange-games-iframe,
      .footer-bar,
      .transfer-page,
      .unmachedBetsWrapper,
      .account-statement-details-settled-void,
      .bonus-statement,
      .bonus-statement-details-settled-void,
      .realCash,
      .live-casino-settled) {
  padding: 35px;
}


div.main-content-wrapper {
  $default-height: 100vh;
  background-color: $primary-background;
  $sum-height: ( $header-height-mobile +  $header-bar-height);
  $minus-height: calc(#{$default-height} - #{$sum-height});
  height: $minus-height;
  overflow-y: auto;
}

@media (max-width: 1023px) {
  div.main-content-wrapper {
    $default-height: 100vh;
    $sum-height: ( $header-height-mobile +  $footer-bar-height);
    $minus-height: calc(#{$default-height} - #{$sum-height});
    overflow-y: auto;
    overflow-scrolling: touch;
    //-webkit-touch-callout: none;
    -webkit-overflow-scrolling: touch;
    height: $minus-height;
    &.without-footer-bar {
      $sum-height: $header-height-mobile;
      $minus-height: calc(#{$default-height} - #{$sum-height});
      //height: $minus-height;
    }
    &.android {
      $sum-height: ( $header-height +  60px + 50px);
      $minus-height: calc(#{$default-height} - #{$sum-height});
      //height: $minus-height !important;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1550px){
  div.main-content-wrapper {
    $default-height: 100vh;
    background-color: $primary-background;
    $sum-height: ( $header-height +  $header-bar-height);
    $minus-height: calc(#{$default-height} - #{$sum-height});
    height: $minus-height;
    overflow-y: auto;
    &.with-ticker {
      $sum-height: ( $header-height +  $header-bar-height + 48px);
      $minus-height: calc(#{$default-height} - #{$sum-height});
      height: $minus-height;
    }
  }
}
@media (max-width: 391px) {
  div.main-content-wrapper {
    $default-height: 100vh;
    $sum-height: ( $header-height-mobile +  $footer-bar-mini-height);
    $minus-height: calc(#{$default-height} - #{$sum-height});
    //height: $minus-height;
    &.android {
      $sum-height: ( $header-height +  60px + 50px);
      $minus-height: calc(#{$default-height} - #{$sum-height});
      //height: $minus-height;
    }
  }
}

.main-content-wrapper {

  .account-statement,
  .profit-loss-report,
  .bet-history,
  .unsettled-bets,
  .button-values,
  .change-password {
    background-color: $primary-background;
    height: 100%;
    overflow: auto;
  }
}

.main-content-wrapper-mobile>div:not(
    .game,
    .sports-event-detail,
    .live-casino,
    .home-page,
    .exchange-games,
    .grouped-games,
    .slot,
    .casinoGame,
    .betby-sportsbook,
    .deposit-timer,
    .footer-bar,
    .exchange-games-iframe,
    .depositPage, .transfer-page,
    .account-statement-details-settled-void,
    .bonus-statement,
    .live-casino-settled,
    .realCash,
    .bonus-statement-details-settled-void ) {
  padding: 15px 10px;
  height: 100%;
}

div.main-content-wrapper-mobile {
  background-color: $primary-background;
}



.main-content-wrapper-mobile {

  .button-values,
  .change-password {
    background-color: $primary-background;
    height: 100%;
    overflow: auto;
  }
}

@include respond(Medium) {
  #root {
    height: 100%;
    //overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      // overflow-y: auto;
      position: fixed;
      top: 0!important;
      left: 0!important;
      right: 0!important;
    }
  }
}

.ios {
  @media (max-width: 851px) {
    div.live-casino__list {
      padding-bottom: 300px!important;
    }



    // div.live-casino.open {
    //   z-index: 99999999;
    // }
    div.live-casino.open {
      .live-casino__list-title {
        display: none;
      }
    }

    aside.side-menu div.wrapper {
      // padding-bottom: 120px;
    }

    div.main-content-wrapper-mobile {
      background-color: $primary-background;
    }


    div.exchange-games .last-results__body {
      padding-bottom: 120px;
    }

    div.button-values {
      padding-bottom: 120px;
    }

    div.last-results__body-button img {
      min-height: 100%;
    }

    div.slot div.slot__wraper {
      padding-bottom: 120px;
    }

    div.sports-event-detail {
      padding-bottom: 120px;
    }

    div.account-statement .table__body {
      padding-bottom: 120px;
      height: 100%;
      // background-color: $primary-background;
    }

    div.account-statement-details .table__body {
      padding-bottom: 120px;
      height: 100%;
      // background-color: $primary-background;
    }

    div.account-statement-details-casino .table__body {
      padding-bottom: 120px;
      height: 100%;
    }

    // div.account-statement-details {
    //   background-color: $primary-background;
    // }
    div.profit-loss-report .table__body {
      padding-bottom: 120px;
      height: 100%;
      // -webkit-overflow-scrolling: touch;
    }

    div.bet-history .table__body {
      padding-bottom: 120px;
      height: 100%;
      // -webkit-overflow-scrolling: touch;
    }

    div.bet-history .desctop-event-tabel {
      padding-bottom: 120px;
    }

    div.grouped-games {
      padding-bottom: 120px;
    }

    div.sport-home-page__bottom-section {
      margin-bottom: 120px;
    }

    div.game-table__body {
      // margin-bottom: 290px;
    }

    div.main-content-wrapper-mobile .table {
      background-color: $primary-background;
    }

    div.global-rules-popup-container {
      padding-bottom: 120px;
    }
  }
}

.trending {
  &::after {
    content: url("./static/images/Trending_desc.svg");
    padding-left: 5px;
    margin-top: 0;
    transition: all 0.5s;
    width: 20px;
    height: 20px;
    position: absolute;
  }
}

.trending-for-casino {
  position: relative;

  &::after {
    content: "";
    margin-top: 0;
    transition: all 0.5s;
    width: 22px;
    height: 22px;
    position: absolute;
    top: -7px;
    right: -1px;
    background: url("./static/images/Trending_desc.svg");
    background-position: center;
    background-size: 22px;
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;
  }
}

@media (max-width: 851px) {
  .trending {
    &::after {
      content: url("./static/images/Trending.svg");
      padding-left: 5px;
      margin-top: 3px;
      transition: all 0.5s;
      width: 15px;
      height: 15px;
      position: absolute;
    }
  }

  .trending-for-casino {
    position: relative;

    &::after {
      padding-left: 5px;
      width: 15px;
      height: 15px;
      top: -5px;
      right: -5px;
      background-size: 15px;
    }
  }
}


#__ybug-launcher.ybug-launcher--right-middle {
  top: 85% !important;

  @media (max-width: 851px) {
    display: none;
  }
}

button.ybug-button {}

div.main-content-wrapper-mobile:has(.depositPage){
  background: white !important;
}


@media (max-width: 1024px) {
  html:has(.container-datepicker-custom){
    font-size: 85%;
  }
}
.container-datepicker-custom {
  .line-through {
    text-decoration-line: unset;
    color: darkgray;
  }
}
  .intercom-lightweight-app-launcher{
  opacity: 0 !important;
  pointer-events: none !important;
  }
.grecaptcha-badge{
  opacity: 0;
}

.intercom-launcher {
  display: none !important;
  pointer-events: none !important;
}
