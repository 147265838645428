@import "phone";
@import '../../../static/scss/variables.scss';

.jili-popup {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  &-content {
    background: $jili-popup-content-bg;
    border-radius: 30px;
    padding: 10px;
    max-width: 1210px;
    width: 40%;
    transform: scale(0.5);
    transition: 0.4s all;

    &.active {
      transform: scale(1);
    }
  }

  &-header {
    &__close {
      position: absolute;
      right: 0;
      border: none;
      background-color: transparent;
      margin-top: 8px;

      img.jiliClose {
        position: unset;
        height: 20px;
        width: 20px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-container {
    padding: 40px;

    &-center {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    &-heading {
      position: relative;
      font-family: Source Sans Pro;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      color: $jili-popup-heading-text;

      span {
        margin-right: 20px;
      }
    }

    &-text {
      font-family: Source Sans Pro;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: $complementary-grey;
      margin-top: 5px;
      margin-bottom: 35px;
    }

    &-description {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $jili-popup-description-text;
      margin-bottom: 10px;
    }

    &__input-wrapper {
      flex-direction: column;
      justify-content: unset;
      height: 83px;
      background: $jili-popup-input-wrapper;

      &-balance {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: $jili-popup-input-wrapper-balance-text;
        margin-top: 10px;
      }

      &__input {

        input {
          width: 50%;
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 24px;
          text-align: center;
          color: $jili-popup-input-wrapper-balance;
          border: none;
          margin: 0 auto;
          background: inherit;
          outline: none;
        }
      }
    }

    &-btns {
      display: flex;
      justify-content: center;

      &--button {
        font-family: Source Sans Pro, sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $jili-popup-container-button-text;
        padding: 13px 41px;
        border-radius: 5px;
        border: none;
        width: 100%;
        max-width: 213px;
        height: 50px;
        background-color: $jili-popup-container-button-bg;
        margin-top: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &-loading{
            content: "";
            position: absolute;
            display: block;
            right: 20px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            animation: loadingSpinner 1s linear infinite;
          }

          @keyframes loadingSpinner {
            to {
              transform: rotate(360deg);
            }
          }

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          background-color: $complementary-grey;
        }
      }

      &-close {
        background-color: $complementary-grey;
      }
    }
  }

  &.blackout {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(16, 27, 68, 0.5);
      z-index: 100;
      transition: all 2s;
    }
  }
}

@media all and (max-width: 1320px) {
  .popup {
    &-content {
      width: 90%;
    }
  }
}
